import React, { useEffect, useState } from "react";
import { currencyByLang } from "../../constants";
import styles from "./index.module.css";
import IssueModal from "../IssueModal";

const Details = (props) => {
  const [orderID, setOrderID] = useState("");
  const [order, setOrder] = useState({ prod: [] });
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(["פתור בעיה", "Resolve Issue"]);
  const [modalText, setModalText] = useState([
    "תארו את הבעיה שלכם ונחזור אלייכם במייל בהקדם האפשרי",
    "Please describe your issue and we will get back to you as soon as possible via email",
  ]);
  const [buttonText, setButtonText] = useState(["שלח", "Send"]);

  async function getData(id) {
    const response = await fetch(
      "https://sunsouls.deviceid.io/order?id=" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + localStorage.getItem("sunsouls-token"),
        },
      },
    );

    if (response.status === 200) {
      const content = await response.json();
      setOrder(content);
      console.log(content);
    } else {
    }
  }

  useEffect(() => {
    const query = window.location.search;
    if (query.includes("?id=")) {
      const id = query.split("?id=")[1];
      if (id.length > 0 && id.length < 255 && id != orderID) {
        getData(id);
        setOrderID(id);
      } else window.location.href = "/";
    } else window.location.href = "/";
  }, []);

  return (
    <section
      className={`${styles.container} w-full py-12 bg-gray-50 sm:py-16 lg:py-20`}
    >
      <div className="px-4 m-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-6xl mx-auto">
          <div className={`${props.lang === 0 && "text-right"} pt-20`}>
            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              {props.lang === 0 ? "פירוט הזמנה" : "Order Details"}
            </h1>
            <p className="mt-2 text-sm font-normal text-gray-600">
              {props.lang === 0
                ? "צפה בפירוט ההזמנה שלך, סטטוס ותיקים פתוחים"
                : "View your order details, status and cases"}
            </p>
          </div>
          <div
            className={`flex justify-center items-center w-[${props.lang === 0 ? "250px" : "350px"} ] gap-3 absolute right-5 top-10`}
          >
            <div>
              <button
                type="button"
                onClick={() => setShow(true)}
                className="
                                          inline-flex
                                          items-center
                                          justify-center
                                          px-4
                                          py-2.5
                                          text-sm
                                          font-bold
                                          text-gray-900
                                          transition-all
                                          duration-200
                                          bg-white
                                          border border-gray-300
                                          rounded-md
                                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                                          hover:bg-gray-100
                                      "
              >
                {props.lang === 1 ? "Resolve Issue" : "פטור בעיה"}
              </button>
            </div>

            <div className="">
              <button
                onClick={() =>
                  (window.location.href = "/invoice?id=" + orderID)
                }
                type="button"
                className="
                                          inline-flex
                                          items-center
                                          justify-center
                                          px-4
                                          py-2.5
                                          text-sm
                                          font-bold
                                          text-gray-900
                                          transition-all
                                          duration-200
                                          bg-white
                                          border border-gray-300
                                          rounded-md
                                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                                          hover:bg-gray-100
                                      "
              >
                {props.lang === 1 ? "View Invoice" : "צפייה בקבלה"}
              </button>
            </div>
          </div>

          <ul className="mt-10 space-y-5 sm:space-y-6 lg:space-y-8">
            <li className="overflow-hidden border border-gray-200 divide-y divide-gray-200">
              <div className="bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                  <div className="inline-grid grid-cols-1 gap-y-4 sm:grid-cols-4 sm:gap-x-20">
                    <div>
                      <p
                        className={`text-sm font-medium text-gray-500 ${props.lang === 0 && " text-right"}`}
                      >
                        {props.lang === 1 ? "Order ID" : "מספר הזמנה"}
                      </p>
                      <p
                        className={`mt-0.5 text-sm font-bold text-gray-900 ${props.lang === 0 && " text-right"}`}
                      >
                        #{order.id}
                      </p>
                    </div>

                    <div>
                      <p
                        className={`text-sm font-medium text-gray-500 ${props.lang === 0 && " text-right"}`}
                      >
                        {props.lang === 1 ? "Date" : "תאריך"}
                      </p>
                      <p
                        className={`mt-0.5 text-sm font-bold text-gray-900 ${props.lang === 0 && " text-right"}`}
                      >
                        {new Date(order.date).toLocaleString("default", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                          weekday: "long",
                        })}
                      </p>
                    </div>

                    <div>
                      <p
                        className={`text-sm font-medium text-gray-500 ${props.lang === 0 && " text-right"}`}
                      >
                        {props.lang === 1 ? "Total Amount" : "סכום כולל"}
                      </p>
                      <p
                        className={`mt-0.5 text-sm font-bold text-gray-900 ${props.lang === 0 && " text-right"}`}
                      >
                        {order.total}
                        {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                      </p>
                    </div>

                    <div>
                      <p
                        className={`text-sm font-medium text-gray-500 ${props.lang === 0 && " text-right"}`}
                      >
                        {props.lang === 1 ? "Tracking Number" : "מספר מעקב"}
                      </p>
                      <p
                        className={`mt-0.5 text-sm font-bold text-gray-900 ${props.lang === 0 && " text-right"}`}
                      >
                        {order.tracking}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flow-root">
                    <ul className="divide-y divide-gray-200 -my-7">
                      {order.prod.map((product, index) => (
                        <li className="py-6">
                          <div className="sm:flex sm:items-center sm:justify-between sm:space-x-5">
                            <div className="flex items-center flex-1">
                              <div className="flex-shrink-0">
                                <img
                                  className="object-cover w-16 h-16 rounded"
                                  src={`data:image/${product.images[0].type};base64, ${product.images[0].data}`}
                                  alt=""
                                />
                              </div>

                              <div className="ml-5">
                                <p className="text-base font-bold leading-tight text-gray-900">
                                  {product.name[props.lang === 1 ? "EN" : "HE"]}
                                </p>

                                <div className="flex items-center mt-1.5 space-x-3 lg:space-x-4">
                                  <p className="text-xs font-medium text-gray-500 sm:text-sm">
                                    {props.lang === 1 ? "Qty: " : "כמות: "}
                                    {order.products[index].qty}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="mt-5 ml-auto sm:mt-0">
                              <button
                                type="button"
                                onClick={() =>
                                  (window.location.href =
                                    "/product?id=" + product._id)
                                }
                                className="
                                                          inline-flex
                                                          items-center
                                                          justify-center
                                                          px-4
                                                          py-2.5
                                                          text-sm
                                                          font-bold
                                                          text-gray-900
                                                          transition-all
                                                          duration-200
                                                          bg-white
                                                          border border-gray-300
                                                          rounded-md
                                                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                                                          hover:bg-gray-100
                                                      "
                              >
                                {props.lang === 1
                                  ? "View Product"
                                  : "צפייה במוצר"}
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <IssueModal
        show={show}
        setShow={setShow}
        lang={props.lang}
        title={modalTitle[props.lang]}
        text={modalText[props.lang]}
        button={buttonText[props.lang]}
        order={order._id}
      />
    </section>
  );
};

export default Details;
