import React, { useEffect, useState } from "react";
import { currencyByLang } from "../../constants";
import IssueModal from "../IssueModal";
import styles from "./index.module.css";

const History = (props) => {
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState(["פתור בעיה", "Resolve Issue"]);
  const [modalText, setModalText] = useState([
    "תארו את הבעיה שלכם ונחזור אלייכם במייל בהקדם האפשרי",
    "Please describe your issue and we will get back to you as soon as possible via email",
  ]);
  const [buttonText, setButtonText] = useState(["שלח", "Send"]);
  async function getData() {
    props.setLoading(true);
    const response = await fetch("https://sunsouls.deviceid.io/history", {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: "Bearer " + localStorage.getItem("sunsouls-token"),
      },
    });
    const content = await response.text();
    if (response.status === 200) {
      const arr = JSON.parse(content);
      console.log(arr);
      if (Array.isArray(arr) && arr.length > 0) {
        setOrders(arr);
      }
      props.setLoading(false);
    } else if (response.status === 401) {
      window.location.href = "/login";
    } else {
      props.setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className={`${styles.container} w-full`}>
        <div className="py-10 bg-gray-50">
          <div
            className={`px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ${props.lang === 0 ? "text-right" : "text-left"}`}
          >
            <h1 className="mt-8 text-2xl font-bold text-gray-900 lg:mt-16 sm:text-3xl ">
              {props.lang === 1 ? "Order history" : "היסטוריית הזמנות"}
            </h1>
            <p className="mt-2 text-sm font-normal text-gray-600">
              {props.lang === 1
                ? "Check the status of recent and old orders & discover more products"
                : "בדוק את הסטטוס של ההזמנות שלך וגלה מוצרים דומים"}
            </p>
          </div>
        </div>

        <div className="py-10 bg-white sm:pb-16 lg:pb-20">
          <div className="mx-auto lg:px-8 max-w-7xl">
            <div className="flow-root">
              <ul className="-my-8 divide-y divide-gray-200 lg:space-y-12 sm:-my-12 lg:-my-0 lg:divide-y-0">
                {orders.map((order) => (
                  <li className="px-4 py-8 sm:px-6 sm:py-12 lg:px-0 lg:py-0">
                    <div className="sm:flex lg:items-center sm:items-start sm:justify-between">
                      <div className="lg:flex space-y-2.5 lg:space-y-0 lg:items-center lg:space-x-12">
                        <p className="text-sm font-medium text-gray-500">
                          {props.lang === 1 ? "Order ID" : "מספר הזמנה"}:{" "}
                          <span className="font-bold text-gray-900">
                            {order.id}
                          </span>
                        </p>

                        <p className="text-sm font-medium text-gray-500">
                          {props.lang === 1 ? "Date" : "תאריך"}:{" "}
                          <span className="font-bold text-gray-900">
                            {new Date(order.date).toLocaleString("default", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              weekday: "long",
                            })}
                          </span>
                        </p>

                        <p className="text-sm font-medium text-gray-500">
                          {props.lang === 1 ? "Order Status" : "סטטוס ההזמנה"}:{" "}
                          <span className="font-bold text-gray-900">
                            {props.lang === 1
                              ? order.status === "COMPLETED"
                                ? "Order Completed"
                                : "Order Incomplete"
                              : order.status !== "COMPLETED"
                                ? "הזמנה לא הושלמה"
                                : "הזמנה הושלמה"}
                          </span>
                        </p>
                      </div>

                      <div className="mt-8 sm:mt-0">
                        <button
                          onClick={() =>
                            (window.location.href = "/invoice?id=" + order._id)
                          }
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-bold text-gray-900 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-100"
                        >
                          {props.lang === 1 ? "View Invoice" : "צפה בקבלה"}
                        </button>
                      </div>

                      <div className="mt-8 sm:mt-0">
                        <button
                          type="button"
                          onClick={() => setShow(true)}
                          className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-bold text-gray-900 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-100"
                        >
                          {props.lang === 1 ? "Resolve Issue" : "פתור בעיה"}
                        </button>
                      </div>

                      <div className="mt-8 sm:mt-0">
                        <button
                          type="button"
                          onClick={() =>
                            (window.location.href = "/order?id=" + order._id)
                          }
                          className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-bold text-gray-900 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-100"
                        >
                          {props.lang === 1 ? "View Order" : "צפה בהזמנה"}
                        </button>
                      </div>
                    </div>

                    <hr className="border-gray-200 mt-7" />

                    <ul className="space-y-8 md:space-y-6 mt-7">
                      {order.prod.map((prod, index) => (
                        <li className="sm:flex sm:items-start sm:justify-between sm:space-x-5">
                          <div className="flex items-stretch flex-1">
                            <div className="flex-shrink-0">
                              <img
                                className="object-cover rounded-lg w-28 h-28"
                                src={`data:image/${prod.images[0].type};base64, ${prod.images[1].data}`}
                                alt=""
                              />
                            </div>

                            <div className="flex flex-col justify-between ml-5">
                              <div className="flex-1">
                                <p className="text-base font-bold text-gray-900">
                                  {props.lang === 1
                                    ? prod.name.EN
                                    : prod.name.HE}
                                </p>
                                <p className="mt-1.5 text-sm font-medium text-gray-500">
                                  {props.lang === 1 ? "Quantity" : "כמות"}: {""}
                                  {order.products[index].qty}
                                </p>
                              </div>

                              <p className="mt-4 text-sm font-medium text-gray-500">
                                {prod.price * (prod.discount / 100) -
                                  (order.promo ? 20 : 0)}
                                {currencyByLang.get(
                                  props.lang === 0 ? "HE" : "EN",
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="flex items-center justify-start mt-5 space-x-5 sm:mt-0 sm:justify-end">
                            <a
                              href={`/product?id=${prod._id}`}
                              title=""
                              className="p-1 -m-1 text-sm font-medium text-gray-500 transition-all duration-200 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            >
                              {" "}
                              {props.lang === 1
                                ? "View Product"
                                : "צפייה במוצר"}
                            </a>

                            <span className="text-gray-200"> | </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <IssueModal
        show={show}
        setShow={setShow}
        lang={props.lang}
        title={modalTitle[props.lang]}
        text={modalText[props.lang]}
        button={buttonText[props.lang]}
        order={orders.length > 0 ? orders[0]._id : ""}
      />
    </>
  );
};

export default History;
