import React from "react";
import styles from "./index.module.css";

const Omri = (props) => {
  return (
    <div className="bg-white w-full md:h-[100vh] h-[200vh] md:mt-[70px] flex items-center justify-start gap-20 md:flex-row flex-col mb-[-100px] sm:mb-[50px]">
      <div className="md:w-[60%] w-[90%] md:h-full h-[20%] flex items-start md:items-center justify-center md:ml-[60px] text-center">
        {props.lang === 1 ? (
          <p>
            <span className="font-bold">
              Nature reminds me to appreciate the little things,
            </span>{" "}
            It reminds me of who I am, and how small we are, we are part of it,
            part of nature. Sunshirts are made for everybody. Each shirt
            represents the country of the photo on the back, It’s a oversize T-
            shirt made out of 100% washed cotton that fits on everybody perfect
            and make you feel like a ray of sun.
          </p>
        ) : (
          <p>
            <span className="font-bold">
              הטבע מזכיר לנו להעריך את הדברים הקטנים,{" "}
            </span>
            הוא מזכיר לנו מי אנחנו וכמה הכל מתגמד מולו, אנחנו חלק ממנו, חלק
            מהטבע. ‫חולצות שמש נוצרו בשביל כולם.‬ ‫כל חולצה היא מדינה אחרת,
            התמונות על הגב הן תמונות מקוריות שצולמו מסביב לעולם. ‬ ‫זוהי חולצת
            טישרט אוברסייז העשויה מ-100% כותנה שטופה שמתאימה לכולם בצורה מושלמת
            וגורמת לך להרגיש כמו קרן שמש.
          </p>
        )}
      </div>
      <div className="w-full md:h-full h-[45%] flex items-center justify-center">
        <div className={`${styles.photo} w-[90%] md:w-[70%] h-[70%]`} />
        <div
          className={`${styles.bottom} ml-[60vw] mb-[75vh] md:mb-[70vh] md:ml-[33vw]`}
        />
        <div
          className={`${styles.top} mr-[85vw] mt-[80vh] md:mt-[65vh] md:mr-[40vw]`}
        />
      </div>
    </div>
  );
};

export default Omri;
