import * as React from "react";

const Menu = (props) => (
  <svg
    className="scale-50 sm:scale-100"
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={45}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#d6b47c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
);
export default Menu;
