import React from "react";
import { EmbedPDF } from "@simplepdf/react-embed-pdf";
import { privacy } from "../../assets";

const Privacy = () => {
  return (
    <div className="flex flex-col absolute w-[100%] top-[70px] left-0 items-center h-full justify-evenly text-right">
      <EmbedPDF
        mode="inline"
        style={{ width: "100%", height: "100%" }}
        documentURL={privacy}
      />
    </div>
  );
};

export default Privacy;
