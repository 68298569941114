import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";
import CountrySelect from "../CountrySelect";

const Register = (props) => {
  const login = useGoogleLogin({
    redirect_uri: "https://sunsouls.store",
    onSuccess: async (codeResponse) => {
      console.log(codeResponse);
      const response = await fetch("https://sunsouls.deviceid.io/google_user", {
        method: "PUT",
        headers: {
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          token: codeResponse.access_token,
          rtl: new Date().getTimezoneOffset() === 180 ? true : false,
        }),
      });
      const content = await response.text();
      if (response.status === 200) {
        localStorage.setItem("sunsouls-token", content);
        window.location.replace("/");
      } else if (response.status === 403) {
        props.setAlertType(false);
        props.setAlertText("A user with that email address already exists");
        props.setShowAlert(true);
        setTimeout(() => props.setShowAlert(false), 3000);
      }
    },
  });

  const [mail, setMail] = useState("");
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [agree, setAgree] = useState(false);
  const [val, setVal] = useState("");
  const [phone, setPhone] = useState("");

  const [passValid, setPassValid] = useState(0);
  const [confirmValid, setConfirmValid] = useState(0);
  const [mailValid, setMailValid] = useState(0);
  const [userValid, setUserValid] = useState(0);
  const [nameValid, setNameValid] = useState(0);
  const [phoneValid, setPhoneValid] = useState(0);
  const [code, setCode] = useState("+972");

  return (
    <section className="absolute top-[75px] w-full h-full bg-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center">
            <h1 className="mt-12 text-3xl font-bold text-gray-900">
              {props.lang === 0 ? "יצירת חשבון בחינם" : "Create free account"}
            </h1>
            <p className="mt-4 text-sm font-medium text-gray-500">
              {props.lang === 0
                ? "הצטרפו לקהילה שלנו ותהיו הראשונים לדעת על מבצעים בלעדיים, הנחות מיוחדות ועוד פעילויות בהמשך, הרשמו עכשיו!"
                : "Join our community and be the first to know about exclusive deals, special discounts and more activities to come, sign up now"}
            </p>
          </div>
          <div className="mt-12">
            <button
              type="button"
              onClick={() => login()}
              className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
            >
              <img
                className="w-5 h-5 mr-2"
                src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg"
                alt=""
              />
              {props.lang === 0 ? "הרשמו עם גוגל" : "Sign up with Google"}
            </button>
          </div>
          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>

            <div className="relative flex justify-center">
              <span className="px-2 text-sm text-gray-400 bg-white">
                {" "}
                {props.lang === 0 ? "או" : "or"}{" "}
              </span>
            </div>
          </div>
          <form className="mt-4">
            <div className="space-y-4">
              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "איימיל" : "Email"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="email"
                  name=""
                  id=""
                  onChange={(e) => {
                    setMail(e.target.value);
                    if (e.target.value.length > 0) {
                      if (
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          e.target.value,
                        )
                      ) {
                        setMailValid(1);
                      } else {
                        setMailValid(2);
                      }
                    } else setMailValid(0);
                  }}
                  placeholder={
                    props.lang === 0 ? "כתובת איימיל" : "Email address"
                  }
                  value={mail}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${mailValid === 1 ? "border-green-300" : mailValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "שם מלא" : "Full Name"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder={props.lang === 0 ? "שם מלא" : "Full Name"}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (
                      e.target.value.length > 3 &&
                      e.target.value.length < 20
                    ) {
                      setNameValid(1);
                    } else if (e.target.value.length === 0) {
                      setNameValid(0);
                    } else {
                      setNameValid(2);
                    }
                  }}
                  value={name}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${nameValid === 1 ? "border-green-300" : nameValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>
              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "שם משתמש" : "Username"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder={props.lang === 0 ? "שם משתמש" : "Username"}
                  onChange={(e) => {
                    setUser(e.target.value);
                    if (
                      e.target.value.length > 2 &&
                      e.target.value.length < 20
                    ) {
                      setUserValid(1);
                    } else if (e.target.value.length === 0) {
                      setUserValid(0);
                    } else {
                      setUserValid(2);
                    }
                  }}
                  value={user}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${userValid === 1 ? "border-green-300" : userValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "סיסמא" : "Password"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="password"
                  name=""
                  id=""
                  placeholder={
                    props.lang === 0
                      ? "לפחות 8 תווים, אות קטנה, גדולה וסימן"
                      : "min. 8 characters, uppercase, lowercase and a sign"
                  }
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                    if (e.target.value.length > 0) {
                      const re =
                        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
                      if (re.test(e.target.value)) {
                        setPassValid(1);
                      } else {
                        setPassValid(2);
                      }
                      if (confirm.length > 0) {
                        if (e.target.value === confirm) {
                          setConfirmValid(1);
                        } else {
                          setConfirmValid(2);
                        }
                      }
                    } else {
                      setPassValid(0);
                    }
                  }}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${passValid === 1 ? "border-green-300" : passValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "אישור סיסמא" : "Confirm Password"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="password"
                  name=""
                  id=""
                  placeholder={
                    props.lang === 0 ? "אישור סיסמא" : "Confirm Password"
                  }
                  value={confirm}
                  onChange={(e) => {
                    setConfirm(e.target.value);
                    if (e.target.value.length > 0) {
                      if (e.target.value === pass) {
                        setConfirmValid(1);
                      } else {
                        setConfirmValid(2);
                      }
                    } else {
                      setConfirmValid(0);
                    }
                  }}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${confirmValid === 1 ? "border-green-300" : confirmValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div className={props.lang === 0 && "flex justify-end"}>
                <label for="" className="text-sm font-bold text-gray-900">
                  {" "}
                  {props.lang === 0 ? "מספר טלפון" : "Phone Number"}{" "}
                </label>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <CountrySelect setTarget={setCode} />
                <input
                  type="phonr"
                  name=""
                  id=""
                  placeholder={props.lang === 0 ? "מספר טלפון" : "Phone Number"}
                  value={phone}
                  onChange={(e) => {
                    const length = e.target.value.length - 1;
                    const char = e.target.value.charAt(length);
                    if (isNaN(char - parseFloat(char))) {
                      if (e.target.value.length > 0) {
                        setPhone(e.target.value.substring(0, length));
                      } else {
                        setPhone("");
                      }
                    } else {
                      setPhone(e.target.value);
                    }

                    setPhoneValid(0);
                    if (
                      /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/.test(
                        e.target.value,
                      )
                    ) {
                      setPhoneValid(1);
                    } else {
                      setPhoneValid(2);
                    }
                  }}
                  className={`flex-1 border block w-full px-4 py-3 placeholder-gray-500 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${phoneValid === 1 ? "border-green-300" : phoneValid === 2 ? "border-red-300" : "border-gray-300"} ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div className="relative flex items-center">
                {props.lang === 1 && (
                  <div className="flex items-center h-5">
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      name="remember-password"
                      id="remember-password"
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                    />
                  </div>
                )}

                <div className="ml-3 w-full flex justify-end">
                  <label
                    for="remember-password"
                    className="text-sm font-medium text-gray-900"
                  >
                    {" "}
                    {props.lang === 0 ? "אני מסכים ל" : "I agree to the"}{" "}
                    <a
                      href="#"
                      title=""
                      className="text-indigo-600 hover:underline"
                    >
                      {props.lang === 0 ? "תנאי שימוש" : "Terms & Conditions"}
                    </a>{" "}
                  </label>
                </div>
                {props.lang === 0 && (
                  <div className="flex items-center h-5 ml-5">
                    <input
                      type="checkbox"
                      checked={agree}
                      onChange={() => setAgree(!agree)}
                      name="remember-password"
                      id="remember-password"
                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                    />
                  </div>
                )}
              </div>
              <div className="w-full flex justify-center">
                <ReCAPTCHA
                  className={"text-right"}
                  onChange={(e) => {
                    setVal(e);
                  }}
                  sitekey="6Lc3670pAAAAANhR_fYQnuS8a9XtOBvxcVqZ1z3F"
                />
              </div>
              <div>
                <div
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 cursor-pointer"
                  onClick={async (e) => {
                    if (mailValid !== 1) {
                      alert("invalid mail");
                      return;
                    }
                    if (nameValid !== 1) {
                      alert("invalid name");
                      return;
                    }
                    if (userValid !== 1) {
                      alert("invalid user");
                      return;
                    }
                    if (passValid !== 1) {
                      alert("invalid pass");
                      return;
                    }
                    if (confirmValid !== 1) {
                      alert("invalid confirm");
                      return;
                    }
                    if (!agree) {
                      alert("please agree");
                      return;
                    }
                    if (val.length === 0) {
                      alert("complete captcha");
                      return;
                    }
                    const response = await fetch(
                      "https://sunsouls.deviceid.io/user",
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "text/plain",
                        },
                        body: JSON.stringify({
                          user,
                          pass,
                          name,
                          mail,
                          val,
                          phone: code + phone,
                          rtl:
                            new Date().getTimezoneOffset() === 180
                              ? true
                              : false,
                        }),
                      },
                    );
                    const content = await response.text();
                    if (response.status === 200) {
                      localStorage.setItem("sunsouls-token", content);
                      localStorage.setItem("sunsouls-phone", phone);
                      window.location.replace("/confirm");
                    } else if (response.status === 406) {
                      props.setAlertType(false);
                      props.setAlertText(content);
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else if (response.status === 501) {
                      props.setAlertType(false);
                      props.setAlertText(
                        "Error while sending SMS, please try again later.",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else {
                      props.setAlertType(false);
                      props.setAlertText(
                        "Unexpected error, please try again later.",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    }
                  }}
                >
                  {props.lang === 0 ? "הרשמה" : "Sign up"}
                </div>
              </div>
            </div>
          </form>
          <div className="mt-6 text-center">
            <p className="text-sm font-medium text-gray-900">
              {props.lang === 0 ? "כבר רשומים" : "Already have an account?"}
              {" ? "}
              <a href="/login" title="" className="font-bold hover:underline">
                {" "}
                {props.lang === 0 ? "התחברו עכשיו" : "Login now"}{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
