import React from "react";
import styles from "./index.module.css";

const Confirm = () => {
  return (
    <section
      className={`py-12 bg-gray-50 sm:py-16 lg:py-20 w-full ${styles.container}`}
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="text-center">
          <p className="text-xs font-bold tracking-widest text-gray-400 uppercase">
            Thank you
          </p>
          <h1 className="mt-3 text-2xl font-bold text-gray-900 sm:text-3xl">
            Your order is confirmed
          </h1>
        </div>

        <div className="max-w-5xl mx-auto mt-8 md:mt-12">
          <div className="overflow-hidden bg-white shadow rounded-xl">
            <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x-4 md:divide-gray-50">
              <div className="px-5 py-6 md:px-8">
                <div className="flow-root md:max-w-xs">
                  <div className="-my-6 divide-y divide-gray-200">
                    <div className="py-6">
                      <h2 className="font-bold text-gray-900 texts-sm">
                        Order Info
                      </h2>
                      <p className="mt-4 text-sm font-normal text-gray-600">
                        Order number:{" "}
                        <span className="text-gray-900">#9483003</span>
                      </p>
                      <p className="mt-1 text-sm font-normal text-gray-600">
                        Date: January 23, 2022
                      </p>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-3 text-xs font-bold text-gray-900 transition-all duration-200 bg-gray-100 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-200"
                        >
                          View invoice
                        </button>
                      </div>
                    </div>

                    <div className="py-6">
                      <h2 className="font-bold text-gray-900 texts-sm">
                        Shipping Address
                      </h2>
                      <p className="mt-4 text-sm font-normal text-gray-600">
                        Wilson Baker
                      </p>
                      <p className="mt-3 text-sm font-normal text-gray-600">
                        4517 Washington Ave. Manchester, Kentucky 39495, USA
                      </p>
                      <p className="mt-3 text-sm font-normal text-gray-600">
                        +1-304-5938
                      </p>
                    </div>

                    <div className="py-6">
                      <h2 className="font-bold text-gray-900 texts-sm">
                        Payment Method
                      </h2>
                      <div className="flex items-start mt-4">
                        <img
                          className="flex-shrink-0 w-6 h-6"
                          src="https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/1/master-card-logo.png"
                          alt=""
                        />
                        <div className="ml-2.5">
                          <p className="text-sm font-normal text-gray-600">
                            Ending with 3990
                          </p>
                          <p className="mt-1 text-sm font-normal text-gray-600">
                            Expires 08/23
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-5 py-6 md:px-8">
                <h3 className="font-bold text-gray-900 texts-sm">
                  Ordered Items
                </h3>

                <div className="flow-root mt-5">
                  <ul className="divide-y divide-gray-200 -my-7">
                    <li className="py-7">
                      <div className="relative flex items-start">
                        <div className="flex-shrink-0">
                          <img
                            className="object-cover w-20 h-20 rounded-lg"
                            src="https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/1/product-1.png"
                            alt=""
                          />
                        </div>

                        <div className="flex flex-col justify-between ml-5 sm:pr-16">
                          <div className="flex-1">
                            <p className="text-sm font-bold text-gray-900">
                              Apple Watch Series 7
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              Golden
                            </p>
                          </div>
                          <p className="mt-4 text-sm font-medium text-gray-500">
                            x 1
                          </p>
                        </div>

                        <div className="absolute bottom-0 right-0 sm:top-0 sm:bottom-auto">
                          <p className="text-sm font-bold text-right text-gray-900">
                            $319
                          </p>
                        </div>
                      </div>
                    </li>

                    <li className="py-7">
                      <div className="relative flex items-start">
                        <div className="flex-shrink-0">
                          <img
                            className="object-cover w-20 h-20 rounded-lg"
                            src="https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/1/product-2.png"
                            alt=""
                          />
                        </div>

                        <div className="flex flex-col justify-between ml-5 sm:pr-16">
                          <div className="flex-1">
                            <p className="text-sm font-bold text-gray-900">
                              Beylob 90 Speaker
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              Space Gray
                            </p>
                          </div>
                          <p className="mt-4 text-sm font-medium text-gray-500">
                            x 1
                          </p>
                        </div>

                        <div className="absolute bottom-0 right-0 sm:top-0 sm:bottom-auto">
                          <p className="text-sm font-bold text-right text-gray-900">
                            $59
                          </p>
                        </div>
                      </div>
                    </li>

                    <li className="py-7">
                      <div className="relative flex items-start">
                        <div className="flex-shrink-0">
                          <img
                            className="object-cover w-20 h-20 rounded-lg"
                            src="https://cdn.rareblocks.xyz/collection/clarity-ecommerce/images/order-confirmation/1/product-3.png"
                            alt=""
                          />
                        </div>

                        <div className="flex flex-col justify-between ml-5 sm:pr-16">
                          <div className="flex-1">
                            <p className="text-sm font-bold text-gray-900">
                              Beoplay M5 Bluetooth Speaker
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              Gray
                            </p>
                          </div>
                          <p className="mt-4 text-sm font-medium text-gray-500">
                            x 1
                          </p>
                        </div>

                        <div className="absolute bottom-0 right-0 sm:top-0 sm:bottom-auto">
                          <p className="text-sm font-bold text-right text-gray-900">
                            $99
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <hr className="mt-6 border-gray-200" />

                <ul className="mt-5 space-y-3">
                  <li className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-600">
                      Sub total
                    </p>
                    <p className="text-sm font-medium text-gray-600">$499</p>
                  </li>

                  <li className="flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Total</p>
                    <p className="text-sm font-bold text-gray-900">$499</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Confirm;
