import React from "react";
import styles from "./index.module.css";

const About = (props) => {
  return (
    <>
      <div
        className={`${styles.container} absolute top-[65px] w-[100%] left-[0px] h-full flex flex-col justify-start items-center px-[10%] pt-[3%]`}
      >
        <h2 className="font-bold text-[40px] text-[#825417] sm:mb-10 mt-7">
          {props.lang === 0 ? "קצת עלינו" : "About Us"}
        </h2>
        <p className="leading-8 tracking-wide text-center text-[18px] md:text-[25px] opacity-[1] text-[#825417] tracking-widest overflow-hidden">
          {props.lang === 0 ? (
            `סאןסולס נוצר בשביל אנשי-שמש.
          אנחנו מאמינים שבכל אחד ואחת יש נפש פראית שמחפשת קיץ אינסופי וחופש.
          סאןסולס זה לא רק מותג, זה דרך חיים שאומרת לחיות כאילו אין מחר.
          בואו להיות חלק מקהילת אנשי-שמש`
          ) : (
            <p>
              SunSouls is a brand created for sun-people. We believe that
              everyone holds a wild spirit within them, Seeking endless summers
              and open roads. SunSouls isn't just a brand, it's a way of life.
              We're here to help you embrace your inner wild child and live life
              to the fullest, because everyone deserves freedom.
              <br /> Become a part of The story of sun-people.
            </p>
          )}
        </p>
      </div>
      <div className="absolute top-[93vh] sm:top-[100vh] right-0 w-full h-[100vh] flex items-center justify-evenly z-20">
        <div
          className={`w-[60%] sm:min-w-[350px] min-w-[200px] h-[90vh] mb-[60px] ${styles.photo}`}
        />
        <div className="w-[40%] h-[70vh] flex justify-center" />
        <p className="relative text-center right-[3px] overflow-hidden md:right-[130px] text-black text-[15px] leading-7 md:text-[25px] font-medium md:w-[60%] ">
          {props.lang === 0
            ? `כל דבר בחיים שלי הוא בעל משמעות, לדבר הכי קטן יש סיפור ומסע,
          תמיד פעלתי ויצרתי מתוך רגש.
          סאןסולס נפתחה בתקופה מאתגרת וקשה לכולנו (ה-7.10) וזאת הדרך שלי לתת נקודת אור בזמן הזה,
          מתוך הרצון שלכולם תהיה את השמש הפנימית
          סאןסולס היא המקום שלכם למצוא השראה, חיבור והרפתקאות חדשות`
            : `Everything in my life is meaningful, the smallest thing has a story and a journey,
        I have always created from emotion.
        Sunsouls opened during a challenging and difficult time for all of us (7th of October), and this is my way of giving a bright spot at this time, from the desire that everyone has the inner sun that I have.`}
        </p>
      </div>
    </>
  );
};

export default About;
