import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { mainServer, currencyByLang } from "../../constants";
import styles from "./index.module.css";
import { Modal } from "../../components";
import { Sizes } from "../../assets";

const Product = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState({});
  const [categories, setCat] = useState([
    { HE: "", EN: "" },
    { HE: "", EN: "" },
  ]);
  const [description, setDescription] = useState("");
  const [discount, setDiscount] = useState(0);
  const [features, setFeatures] = useState({ HE: [], EN: [] });
  const [images, setImages] = useState([]);
  const [price, setPrice] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [tags, setTags] = useState([]);
  const [avgReview, setAvgReview] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const [id, setID] = useState(null);
  const [size, setSize] = useState(0);
  const [stock, setStock] = useState([0, 0, 0, 0]);

  const [active, setActive] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const [reviewsLoaded, setReviewsLoaded] = useState(1);
  const [ready, setReady] = useState(false);
  const [mini, setMini] = useState("");

  const [out, setOut] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [buttonText, setButtonText] = useState(["הוסף", "Add"]);
  const [modalTitle, setModalTitle] = useState([
    "הוספת ביקורת",
    "Add A New Review",
  ]);
  const [modalText, setModalText] = useState([
    "הוסף ביקורת חדשה למוצר שנבחר, אנא פרטו ככל שאפשר :)",
    "Add a new review for the selected product, please be as descriptive as possible :)",
  ]);

  const [review, setReview] = useState({});

  useEffect(() => {
    props.setLoading(true);
    const id = searchParams.get("id");
    if (id == null || id === undefined || id.length !== 24) return;
    setID(id);
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        props.setLoading(false);
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          console.log(data);
          for (const stock of data.stock) {
            if (stock >= 0) setOut([...out, stock]);
          }
          setStock(data.stock);
          setName(data.name);
          setCat(data.category);
          setDescription(data.description);
          setDiscount(data.discount);
          setFeatures(data.features);
          setImages(data.images);
          setPrice(data.price);
          setReviews(data.reviews);
          setTags(data.tags);
          setReviewData(data.reviews_data);
          setMini(data.mini);
          setAvgReview(
            parseInt(
              data.reviews.reduce(
                (accumulator, currentValue) => accumulator + currentValue[1],
                0,
              ) / data.reviews.length,
            ),
          );
          if (data.stock[0] === 0) {
            if (data.stock[1] === 0) {
              if (data.stock[2] === 0) {
                data.stock[3] > 0 && setSize(3);
              } else {
                setSize(2);
              }
            } else {
              setSize(1);
            }
          }
          setReady(true);
        }
      }
    };
    xhr.open("GET", mainServer + "product?id=" + id);
    // xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('JWT'));
    xhr.setRequestHeader("Content-Type", "text/plain");
    xhr.send();
  }, []);

  useEffect(() => {
    if (review.val !== undefined) {
      if (reviews.length < 9 || reviewData.length >= reviews.length) {
        setReviewData([...reviewData, review]);
        setReview({});
      }
    }
  }, [review]);

  return (
    <section
      className={`${styles.container} absolute top-[73px] z-[1] py-12 bg-gray-50 sm:py-16 w-full`}
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <nav className={`flex ${props.lang === 0 && "float-right"}`}>
          <ol role="list" className="flex items-center space-x-0.5">
            <li>
              <div className="-m-1">
                <a
                  href={categories[0].url}
                  className="p-1 text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                >
                  {" "}
                  {categories[0][props.lang === 0 ? "HE" : "EN"]}{" "}
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
                <div className="-m-1">
                  <a
                    href={categories[1].url}
                    className="p-1 ml-0.5 text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:ring-2 focus:text-gray-900 focus:ring-gray-900 hover:text-gray-700"
                  >
                    {" "}
                    {categories[1][props.lang === 0 ? "HE" : "EN"]}{" "}
                  </a>
                </div>
              </div>
            </li>
          </ol>
        </nav>

        <div
          className={`grid grid-cols-1 mt-8 lg:grid-rows-1 gap-y-12 lg:mt-12 lg:grid-cols-5 lg:gap-y-16 lg:gap-x-12 xl:gap-x-16 ${props.lang === 0 && "text-right"}`}
        >
          <div className="lg:col-span-3 lg:row-end-1">
            <div className="lg:flex lg:items-start">
              <div className="lg:order-2 lg:ml-5">
                <div className="overflow-hidden border-2 border-transparent rounded-lg">
                  <img
                    className="object-cover w-full h-full"
                    src={
                      images.length > 0
                        ? `data:image/${images[activeImage].type};base64, ${images[activeImage].data}`
                        : ""
                    }
                    alt=""
                  />
                </div>
              </div>

              <div className="w-full lg:w-32 mt-2.5 lg:mt-0 lg:flex-shrink-0 lg:order-1 ">
                <div className="flex flex-row items-stretch lg:flex-col lg:space-y-5 space-x-2.5 lg:space-x-0 ">
                  {images.length > 0 &&
                    images.map((image, index) => (
                      <button
                        type="button"
                        className="flex-1"
                        onClick={() => setActiveImage(index)}
                      >
                        <div
                          className={`overflow-hidden rounded-lg aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 border-2 ${activeImage === index ? "border-gray-900" : "border-transparent"}`}
                        >
                          <img
                            className="object-cover w-full h-full"
                            src={`data:image/${image.type};base64, ${image.data}`}
                            alt=""
                          />
                        </div>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-2 lg:row-end-2 lg:row-span-2">
            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              {name[props.lang === 1 ? "EN" : "HE"]}
            </h1>

            <div
              className={`flex items-center mt-5 ${props.lang === 0 && "float-right"}`}
            >
              {props.lang === 1 && (
                <div className="flex items-center space-x-px" key={reviews}>
                  {new Array(avgReview).fill(null).map(() => (
                    <svg
                      className="w-4 h-4 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  ))}
                </div>
              )}
              <p className="ml-2 text-sm font-medium text-gray-400">
                {reviews.length} {props.lang === 1 ? "Reviews" : "ביקורות"}
              </p>
              {props.lang === 0 && (
                <div
                  className="flex items-center space-x-px ml-3"
                  key={reviews}
                >
                  {new Array(avgReview).fill(null).map(() => (
                    <svg
                      className="w-4 h-4 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  ))}
                </div>
              )}
            </div>

            <div
              className={`flex items-center mt-8 ${props.lang === 0 && "justify-end pt-8"} w-full`}
            >
              <p className="text-3xl font-bold text-gray-900">
                {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                {parseInt(price * (discount / 100)) /
                  (props.lang === 0 ? 1 : 3.5)}{" "}
              </p>
              <p className="ml-2 text-2xl font-bold text-gray-500 ">
                <del>
                  {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                  {price / (props.lang === 0 ? 1 : 3.5)}{" "}
                </del>
              </p>
            </div>

            <div
              className={`flex items-center mt-3 text-sm font-medium text-gray-500 ${props.lang === 0 && "float-right"}`}
            >
              {props.lang === 1 && (
                <svg
                  className="w-4 h-4 mr-2.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
              {props.lang === 1
                ? `Enjoy ${100 - discount}% Launch Discount`
                : `קונים במחיר השקה ונהנים מ${100 - discount}% הנחה`}
              {props.lang === 0 && (
                <svg
                  className="w-4 h-4 ml-2.5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </div>

            <h2
              className={`mt-8 ${props.lang === 0 && "pt-8"} text-base font-bold text-gray-900`}
            >
              {props.lang === 1 ? "Features" : "תכונות"}
            </h2>
            <ul
              dir="auto"
              className="mt-4 space-y-3 text-base font-medium text-gray-600 list-disc list-inside "
            >
              {features[props.lang === 0 ? "HE" : "EN"].map((feature) => (
                <li className="">{feature}</li>
              ))}
            </ul>
            <div
              className={`w-[100%] h-[50px] gap-[20px] flex items-center ${props.lang === 0 ? "justify-end" : "justify-start"} mt-[15px]`}
            >
              <div
                onClick={() => stock[0] > 0 && setSize(0)}
                className={`cursor-pointer flex items-center justify-center w-[50px] h-[50px] border-[1px] ${stock[0] > 0 ? size === 0 && "border-[#d6b47c]" : "border-gray-200"} rounded`}
              >
                <p
                  className={
                    stock[0] > 0
                      ? size === 0 && "text-[#d6b47c]"
                      : "text-gray-200"
                  }
                >
                  S
                </p>
              </div>
              <div
                onClick={() => stock[1] > 0 && setSize(1)}
                className={`cursor-pointer flex items-center justify-center w-[50px] h-[50px] border-[1px] ${size === 1 && "border-[#d6b47c]"} rounded`}
              >
                <p className={size === 1 && "text-[#d6b47c]"}>M</p>
              </div>
              <div
                onClick={() => stock[2] > 0 && setSize(2)}
                className={`cursor-pointer flex items-center justify-center w-[50px] h-[50px] border-[1px] ${size === 2 && "border-[#d6b47c]"} rounded`}
              >
                <p className={size === 2 && "text-[#d6b47c]"}>L</p>
              </div>
              <div
                onClick={() => stock[3] > 0 && setSize(3)}
                className={`cursor-pointer flex items-center justify-center w-[50px] h-[50px] border-[1px] ${size === 3 && "border-[#d6b47c]"} rounded`}
              >
                <p className={size === 3 && "text-[#d6b47c]"}>XL</p>
              </div>
            </div>
            <div
              className={`flex items-center ${props.lang === 0 && "justify-end"} mt-10 space-x-4`}
            >
              <button
                type="button"
                onClick={async () => {
                  var arr = [];
                  const temp = localStorage.getItem("sunsouls-cart");
                  if (temp !== null && temp !== undefined) {
                    arr = JSON.parse(temp);
                    if (!Array.isArray(arr)) arr = [];
                  }
                  var found = false;
                  var index = 0;
                  for (const item of arr) {
                    if (item.id === id && item.size === size) {
                      found = true;
                      break;
                    }
                    index++;
                  }
                  if (found) {
                    const tmp = arr[index];
                    tmp.qty = tmp.qty + 1;
                    arr[index] = tmp;
                  } else {
                    const obj = {
                      id,
                      name,
                      qty: 1,
                      price: price * (discount / 100),
                      img: { data: mini, type: "png" },
                      size,
                    };
                    arr.push(obj);
                  }
                  localStorage.setItem("sunsouls-cart", JSON.stringify(arr));
                  props.setRefresh(!props.refresh);

                  if (localStorage.getItem("sunsouls-logged") == 1) {
                    const response = await fetch(
                      "https://sunsouls.deviceid.io/cart",
                      {
                        method: "PUT",
                        headers: {
                          "Content-Type": "text/plain",
                          Authorization:
                            "Bearer " + localStorage.getItem("sunsouls-token"),
                        },
                        body: JSON.stringify(
                          arr.map((item) => {
                            return { id: item.id, qty: item.qty };
                          }),
                        ),
                      },
                    );
                    /*
                    const content = await response.text();
                    if (response.status === 200) {
                    } else if (response.status === 401) {
                      window.location.href = "/login";
                    }
                     */
                  }
                  props.setAlertType(true);
                  props.setAlertText(
                    props.lang === 0
                      ? "המוצר נוסף בהצלחה"
                      : "Item Successfully Added",
                  );
                  props.setShowAlert(true);
                  setTimeout(() => props.setShowAlert(false), 3000);
                }}
                disabled={!ready}
                className={`inline-flex items-center justify-center px-12 py-3 text-base font-bold leading-7 text-white transition-all duration-200 ${stock[size] > 0 ? "bg-[#d6b47c]" : "bg-gray-200"} border-2 border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900`}
              >
                {props.lang === 1 ? "Add to cart" : "הוספה לסל"}
              </button>
              {/*
              <button
                type="button"
                className="
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-3.5
                            text-gray-900
                            transition-all
                            duration-200
                            bg-transparent
                            border-2 border-gray-300
                            rounded-md
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900
                            hover:border-gray-900 hover:bg-gray-100
                            focus:border-gray-900
                        "
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
              </button>
               */}
            </div>

            <ul className="mt-8 space-y-3">
              <li
                className={`flex items-center ${props.lang === 0 && "justify-end"} text-sm font-medium text-gray-500`}
              >
                {props.lang === 1 && (
                  <svg
                    className="w-5 h-5 mr-2.5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
                {props.lang === 1 ? "International Shipping" : "משלוח בינלאומי"}
                {props.lang === 0 && (
                  <svg
                    className="w-5 h-5 ml-2.5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
              </li>

              <li
                className={`flex items-center ${props.lang === 0 && "justify-end"} text-sm font-medium text-gray-500`}
              >
                {props.lang === 1 && (
                  <svg
                    className="w-5 h-5 mr-2.5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                )}
                {props.lang === 1
                  ? "100% Secured Payment"
                  : "תשלום מאובטח 100%"}
                {props.lang === 0 && (
                  <svg
                    className="w-5 h-5 ml-2.5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    />
                  </svg>
                )}
              </li>
            </ul>
          </div>

          <div className="lg:col-span-3">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px space-x-8 sm:space-x-14">
                <a
                  title=""
                  className={`cursor-pointer inline-flex items-center py-4 text-sm font-medium whitespace-nowrap ${active === 0 ? "text-gray-900 border-b-2 border-gray-900" : "text-gray-500 border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300"}`}
                  onClick={() => setActive(0)}
                >
                  {" "}
                  {props.lang === 1 ? "Description" : "תיאור מוצר"}{" "}
                </a>

                <a
                  title=""
                  className={`cursor-pointer inline-flex items-center py-4 text-sm font-medium whitespace-nowrap ${active === 1 ? "text-gray-900 border-b-2 border-gray-900" : "text-gray-500 border-b-2 border-transparent hover:text-gray-700 hover:border-gray-300"}`}
                  onClick={() => setActive(1)}
                >
                  {props.lang === 1 ? "Reviews" : "ביקורות"}
                  <span className="block px-2 py-0.5 ml-2 text-xs font-bold bg-gray-400 rounded-full text-gray-50">
                    {" "}
                    {reviews.length}{" "}
                  </span>
                </a>
              </nav>
            </div>
            {active === 0 ? (
              <>
                <p
                  className={`w-full h-[170px] text-black mt-[20px] ${props.lang === 0 && "text-right"}`}
                >
                  {description[props.lang === 0 ? "HE" : "EN"]}
                </p>
                <p className="mt-[30px] font-bold">
                  {props.lang === 0
                    ? "לשמירה על איכות החולצה יש לכבס עד 30 מעלות"
                    : ""}
                </p>
                <br />
                <p className="font-medium text-[22x] mb-[20px]">
                  {props.lang === 0 ? "? מה המידה שלי" : "What's my size ?"}
                </p>
                <img src={Sizes} />
              </>
            ) : active === 1 ? (
              <>
                <div className="flow-root mt-8 sm:mt-12">
                  <ul
                    key={reviewData.length}
                    className="divide-y divide-gray-100 -my-9"
                  >
                    {reviewData.map((data) => (
                      <li className="py-8">
                        <div
                          className={`flex items-center ${props.lang === 0 && "justify-end"}`}
                        >
                          <div className="ml-6">
                            <div
                              className={`flex items-center ${props.lang === 0 && "justify-end"} space-x-px`}
                            >
                              {new Array(data.rating).fill(null).map(() => (
                                <svg
                                  className="w-6 h-6 text-amber-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                              ))}
                            </div>
                            <p className="mt-5 text-base font-normal leading-7 text-gray-900 w-full">
                              {data.val}
                            </p>
                            <p className="mt-5 text-sm font-bold text-gray-900">
                              {data.name}
                            </p>
                            <p className="mt-1 text-sm font-normal text-gray-500">
                              {new Date(data.date).toDateString()}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-8 text-center sm:mt-12 lg:text-left flex align-center justify-evenly w-[400px]">
                  {reviews.length > 9 && reviewData.length < reviews.length && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center text-xs font-bold tracking-widest text-gray-400 uppercase transition-all duration-200 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      onClick={async () => {
                        if (id !== null) {
                          const response = await fetch(
                            "https://sunsouls.deviceid.io/reviews?page=" +
                              reviewsLoaded +
                              "&id=" +
                              id,
                          );
                          if (!response.ok) {
                            // show error alert
                            return;
                          }
                          const json = await response.json();
                          const newData = reviewData.concat(json);
                          console.log(newData);
                          setReviewData(newData);
                        }
                      }}
                    >
                      <svg
                        className="w-4 h-4 mr-3"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                      {props.lang === 1
                        ? "Load more reviews"
                        : "טען ביקורות נוספות"}
                    </button>
                  )}

                  <button
                    onClick={() => setShowModal(true)}
                    className="inline-flex items-center justify-center text-xs font-bold tracking-widest text-gray-400 uppercase transition-all duration-200 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    {props.lang === 1 ? "Add Review" : "הוספת ביקורת"}
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        title={modalTitle[props.lang]}
        text={modalText[props.lang]}
        button={buttonText[props.lang]}
        lang={props.lang}
        prod={id}
        setReview={setReview}
      />
    </section>
  );
};

export default Product;
