import React from "react";
import { LogoBlack } from "../../assets";
import styles from "./index.module.css";

const Thanks = (props) => {
  return (
    <div className="w-[100vw] h-[100vh] absolute top-[70px] flex flex-col items-center justify-center bg-white">
      <div className="w-full h-[30%] bg-[#c2a984] flex items-center justify-center">
        <img src={LogoBlack} className="w-[30vh] h-[30vh]" />
      </div>
      <div className="h-[70%] w-full flex flex-col items-center justify-top mt-[50px]">
        <p className="w-[60%] text-center">
          <span className="font-bold">
            {props.lang === 0
              ? "! תודה שבחרתם בנו"
              : "Thank you for choosing us!"}
          </span>
          <br />
          {props.lang === 0
            ? "אנחנו שמחים שהצטרפת למשפחת אנשי השמש ! ההזמנה שלך אושרה בהצלחה "
            : "We are happy that you have joined the Sun People family Your order has been successfully confirmed."}
        </p>
      </div>
    </div>
  );
};

export default Thanks;
