import React, { useEffect, useState } from "react";
import { currencyByLang } from "../../constants";
import styles from "./index.module.css";

const Featured = (props) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await fetch("https://sunsouls.deviceid.io/featured", {
      method: "GET",
    });

    if (response.status === 200) {
      const content = await response.json();
      console.log(content);
      setItems(content);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <section className={`${styles.container} w-full bg-white`}>
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-md mx-auto text-center">
          <h2 className="text-2xl font-bold text-[#d6b47c] sm:text-3xl">
            {props.lang === 1
              ? "Sunshirts Collection"
              : "קולקציית חולצות השמש שלנו"}
          </h2>
          <p className="mt-4 mb-6 text-base font-normal leading-7">
            {props.lang === 1
              ? "Limited Time Launch Sale"
              : "עכשיו במחירי השקה לזמן מוגבל"}
          </p>
        </div>

        <div className="grid grid-cols-1 gap-6 mt-5 text-center sm:grid-cols-2 lg:gap-5 lg:grid-cols-4 sm:mt-0">
          {items.map((item) => (
            <div
              onClick={() => {
                window.location.href = "/product?id=" + item.product;
              }}
              className="cursor-pointer relative bg-[#F5F5F5] overflow-hidden flex flex-col"
            >
              <div
                className={`z-10 absolute top-3 ${props.lang === 0 ? "right-3" : "left-3"}`}
              >
                <p className="inline-flex items-center justify-center px-2 py-2 text-xs font-bold tracking-wide text-white uppercase bg-gray-600 rounded">
                  {props.lang === 1 ? "Launch Price" : "מחיר השקה"}
                </p>
              </div>
              <div className="relative group h-[500px]">
                <div className="overflow-hidden aspect-w-1 aspect-h-1">
                  <img
                    className="absolute object-cover w-full h-full transition-all duration-300 group-hover:scale-110"
                    src={`data:image/${item.img.type};base64, ${item.img.data}`}
                    alt=""
                  />
                </div>
              </div>
              <h3 className="w-full text-base font-bold text-gray-900">
                <a href="#" title="">
                  {item.name[props.lang === 1 ? "EN" : "HE"]}
                  <span className="absolute inset-0" aria-hidden="true"></span>
                </a>
              </h3>
              <p className="mb-[50px] text-sm font-bold text-gray-500">
                {(item.price * (item.discount / 100)) /
                  (props.lang === 0 ? 1 : 3.5)}{" "}
                {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
              </p>
              <div className="absolute bottom-0 w-full">
                <button
                  type="button"
                  className="relative flex items-center justify-center w-full px-4 py-4 mt-8 text-sm font-bold text-gray-900 transition-all duration-200 bg-transparent hover:text-white group"
                >
                  <span className="absolute bottom-0 inset-0 h-full transition-all duration-200 origin-bottom translate-y-full bg-[#d6b47c] group-hover:translate-y-0"></span>
                  <span className="relative">
                    {props.lang === 0 ? "צפה במוצר" : "View Product"}
                  </span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <div className="w-full flex justify-center items-center">
          <div class=" z-10">
            <svg
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              enable-background="new 0 0 40 40"
              fill="#ff6700"
            >
              <path
                opacity="0.2"
                fill="#ff6700"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill="#ff6700"
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="0.5s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        </div>
      )}
    </section>
  );
};

export default Featured;
