import React, { useState, useEffect } from "react";
import { currencyByLang } from "../../constants";
import styles from "./index.module.css";

const Invoice = (props) => {
  const [transactionID, setTransactionID] = useState("");
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [number, setNumber] = useState("");
  const [items, setItems] = useState([]);
  const [paypal, setPaypal] = useState(false);
  const [info, setInfo] = useState([]);
  const [promo, setPromo] = useState(false);
  const [shipping, setShipping] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [mail, setMail] = useState("");
  const [tid, setTID] = useState("");
  const [promoTotal, setPromoTotal] = useState(0);

  useEffect(() => {
    const search = window.location.search;
    if (search.length < 10 || !search.includes("?id="))
      window.location.href = "/";
    const id = search.split("?id=")[1];
    setNumber(id);
    getData(id);
  }, []);

  async function getData(id) {
    const response = await fetch(
      "https://sunsouls.deviceid.io/order?id=" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Bearer " + localStorage.getItem("sunsouls-token"),
        },
      },
    );

    if (response.status === 200) {
      const content = await response.json();
      try {
        if (typeof content.shipping === "string") {
          // PayPlus
          setName(content.name);
          if (content.shipping === ",  , , .") setShipping("Self Pickup");
          else setShipping(content.shipping);
        } else {
          // PayPal
          setName(content.shipping.full_name);
        }
        setMail(content.mail);
        setTransactionID(content.id);
        setItems(content.prod);
        setInfo(content.products);
        setTID(content.tid);
        content.promo && setPromo(true);
        var total = 0;
        var promoTotal = 0;
        for (const item of content.prod) {
          const val = item.price * (item.discount / 100);
          total += val;
          promoTotal += val - 20;
        }
        setPromoTotal(promoTotal);
        setPrice(total);
        var item_count = 0;
        for (const item of content.products) {
          item_count += item.qty;
        }
        if (item_count < 3) {
          if (content.shipping_type === 1) setShippingPrice(18);
          else if (content.shipping_type === 2) setShippingPrice(35);
        }
      } catch (e) {
        console.log(e);
      }
      console.log(content);
    } else {
    }
  }

  return (
    <div className={`${styles.container} absolute top-[100px] w-full`}>
      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        align="center"
        class="fullTable"
      >
        <tr>
          <td height="20"></td>
        </tr>
        <tr>
          <td>
            <table
              width="600"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
              bgcolor="#ffffff"
              style={{ borderRadius: "10px 10px 0 0" }}
            >
              <tr class="hiddenMobile">
                <td height="40"></td>
              </tr>
              <tr class="visibleMobile">
                <td height="30"></td>
              </tr>

              <tr>
                <td>
                  <table
                    width="480"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    class="fullPadding"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            align="left"
                            class="col"
                          >
                            <tbody>
                              <tr>
                                <td align="center">
                                  {" "}
                                  <img
                                    width={186}
                                    height={73.5}
                                    src={require("../../assets/logo-gold.png")}
                                    alt="logo"
                                    border="0"
                                  />
                                </td>
                              </tr>
                              <tr class="hiddenMobile">
                                <td height="40"></td>
                              </tr>
                              <tr class="visibleMobile">
                                <td height="20"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    float: props.lang === 0 ? "right" : "left",
                                    fontSize: "12px",
                                    color: "#5b5b5b",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "18px",
                                    verticalAlign: "top",
                                    textAlign:
                                      props.lang === 0 ? "right" : "left",
                                  }}
                                >
                                  {props.lang === 1 ? (
                                    <>
                                      Hello, {name}.
                                      <br /> Thank you for placing an order at
                                      our store.
                                    </>
                                  ) : (
                                    <>
                                      שלום, {name}.
                                      <br />! תודה שבחרת להזמין דרכנו
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            width="100%"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            align="right"
                            class="col"
                          >
                            <tbody>
                              <tr class="visibleMobile">
                                <td height="20"></td>
                              </tr>
                              <tr>
                                <td height="5"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "21px",
                                    letterSpacing: "-1px",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "1",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                  }}
                                >
                                  {props.lang === 1 ? "Receipt" : "קבלה"}
                                </td>
                              </tr>
                              <tr />
                              <tr class="hiddenMobile">
                                <td height="50"></td>
                              </tr>
                              <tr class="visibleMobile">
                                <td height="20"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    color: "#5b5b5b",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "18px",
                                    verticalAlign: "top",
                                    textAlign: "center",
                                  }}
                                >
                                  <small>
                                    {props.lang === 1 ? "NUMBER" : "מספר"}
                                  </small>{" "}
                                  {props.lang === 1 && "#"}
                                  {transactionID}
                                  {props.lang === 0 && "#"}
                                  <br />
                                  <small>{date}</small>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        align="center"
        class="fullTable"
      >
        <tbody>
          <tr>
            <td>
              <table
                width="600"
                border="0"
                cellpadding="0"
                cellspacing="0"
                align="center"
                class="fullTable"
                bgcolor="#ffffff"
              >
                <tbody>
                  <tr />
                  <tr class="hiddenMobile">
                    <td height="60"></td>
                  </tr>
                  <tr class="visibleMobile">
                    <td height="40"></td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        width="480"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        align="center"
                        class="fullPadding"
                      >
                        {items.map((item, index) => {
                          const current = info[index];
                          return (
                            <tbody>
                              <tr>
                                <th
                                  style={{
                                    fontSize: "12px",
                                    color: "#5b5b5b",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "1",
                                    fontWeight: "normal",
                                    verticalAlign: "top",
                                    textAlign: "left",
                                    padding: "0 10px 7px 0",
                                  }}
                                  width="52%"
                                  align="left"
                                >
                                  {props.lang === 1 ? "Item" : "פריט"}
                                </th>

                                <th
                                  style={{
                                    fontSize: "12px",
                                    color: "#5b5b5b",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "1",
                                    fontWeight: "normal",
                                    verticalAlign: "top",
                                    textAlign: "left",
                                    padding: "0 0 7px",
                                  }}
                                  align="center"
                                >
                                  {props.lang === 1 ? "Quantity" : "כמות"}
                                </th>
                                <th
                                  style={{
                                    fontSize: "12px",
                                    color: "#5b5b5b",
                                    fontFamily: "Open Sans, sans-serif",
                                    lineHeight: "1",
                                    fontWeight: "normal",
                                    verticalAlign: "top",
                                    textAlign: "left",
                                    padding: "0 0 7px",
                                  }}
                                  align="right"
                                >
                                  {props.lang === 1 ? "Price" : "מחיר"}
                                </th>
                              </tr>
                              <tr>
                                <td
                                  height="1"
                                  style={{ background: "#bebebe" }}
                                  colspan="4"
                                ></td>
                              </tr>
                              <tr>
                                <td height="10" colspan="4"></td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Open Sans, sans-serif",
                                    color: "#81ecec",
                                    lineHeight: "18px",
                                    verticalAlign: "top",
                                    padding: "10px 0",
                                  }}
                                  class="article"
                                >
                                  {item.name[props.lang === 0 ? "HE" : "EN"] +
                                    " (" +
                                    (current.size === 0
                                      ? "S"
                                      : current.size === 1
                                        ? "M"
                                        : current.size === 2
                                          ? "L"
                                          : "XL") +
                                    ")"}
                                </td>

                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Open Sans, sans-serif",
                                    color: "#646a6e",
                                    lineHeight: "18px",
                                    verticalAlign: "top",
                                    padding: "10px 0",
                                  }}
                                  align="center"
                                >
                                  {current.qty}
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Open Sans, sans-serif",
                                    color: "#1e2b33",
                                    lineHeight: "18px",
                                    verticalAlign: "top",
                                    padding: "10px 0",
                                  }}
                                  align="right"
                                >
                                  {(
                                    (item.price * (item.discount / 100) -
                                      (promo ? 20 : 0)) /
                                    (props.lang === 0 ? 1 : 3.5)
                                  ).toFixed(2)}
                                  {currencyByLang.get(
                                    props.lang === 0 ? "HE" : "EN",
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="20"></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        align="center"
        class="fullTable"
      >
        <tbody>
          <tr>
            <td>
              <table
                width="600"
                border="0"
                cellpadding="0"
                cellspacing="0"
                align="center"
                class="fullTable"
                bgcolor="#ffffff"
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        width="480"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        align="center"
                        class="fullPadding"
                      >
                        <tbody>
                          <tr>
                            {props.lang === 0 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#646a6e",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                  whiteSpace: "nowrap",
                                }}
                                width="80"
                              >
                                {price}
                                {currencyByLang.get("HE")}
                              </td>
                            )}
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: "Open Sans, sans-serif",
                                color: "#646a6e",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                              }}
                            >
                              {props.lang === 1 ? "Subtotal" : "סכום בניים"}
                            </td>
                            {props.lang === 1 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#646a6e",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                  whiteSpace: "nowrap",
                                }}
                                width="80"
                              >
                                {(price / 3.5).toFixed(2)}
                                {currencyByLang.get("EN")}
                              </td>
                            )}
                          </tr>
                          <tr>
                            {props.lang === 0 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#646a6e",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                {shippingPrice}
                                {currencyByLang.get("HE")}
                              </td>
                            )}
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: "Open Sans, sans-serif",
                                color: "#646a6e",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                              }}
                            >
                              {props.lang === 1
                                ? "Shipping & Handling"
                                : "משלוח"}
                            </td>
                            {props.lang === 1 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#646a6e",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                {(shippingPrice / 3.5).toFixed(2)}
                                {currencyByLang.get("EN")}
                              </td>
                            )}
                          </tr>
                          {promo && (
                            <tr>
                              {props.lang === 0 && (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Open Sans, sans-serif",
                                    color: "#b0b0b0",
                                    lineHeight: "22px",
                                    verticalAlign: "top",
                                    textAlign: "right",
                                  }}
                                >
                                  -10%
                                </td>
                              )}
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#b0b0b0",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                {props.lang === 0
                                  ? "הנחת פרומו"
                                  : "PROMO DISCOUNT"}
                              </td>
                              {props.lang === 1 && (
                                <td
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Open Sans, sans-serif",
                                    color: "#b0b0b0",
                                    lineHeight: "22px",
                                    verticalAlign: "top",
                                    textAlign: "right",
                                  }}
                                >
                                  -10%
                                </td>
                              )}
                            </tr>
                          )}
                          <tr>
                            {props.lang === 0 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#000",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                <strong>
                                  {(promo ? promoTotal : price) + shippingPrice}
                                  {currencyByLang.get("HE")}
                                </strong>
                              </td>
                            )}
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: "Open Sans, sans-serif",
                                color: "#000",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                              }}
                            >
                              <strong>
                                {props.lang === 1
                                  ? "Grand Total (Incl.Tax)"
                                  : "סכום כולל מיסים"}
                              </strong>
                            </td>
                            {props.lang === 1 && (
                              <td
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Open Sans, sans-serif",
                                  color: "#000",
                                  lineHeight: "22px",
                                  verticalAlign: "top",
                                  textAlign: "right",
                                }}
                              >
                                <strong>
                                  $
                                  {(
                                    (price * (promo ? 0.9 : 1) +
                                      shippingPrice) /
                                    3.5
                                  ).toFixed(0)}
                                </strong>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: "Open Sans, sans-serif",
                                color: "#b0b0b0",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                              }}
                            ></td>
                            <td
                              style={{
                                fontSize: "12px",
                                fontFamily: "Open Sans, sans-serif",
                                color: "#b0b0b0",
                                lineHeight: "22px",
                                verticalAlign: "top",
                                textAlign: "right",
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        align="center"
        class="fullTable"
      >
        <tbody>
          <tr>
            <td>
              <table
                width="600"
                border="0"
                cellpadding="0"
                cellspacing="0"
                align="center"
                class="fullTable"
                bgcolor="#ffffff"
              >
                <tbody>
                  <tr>
                    <tr class="hiddenMobile">
                      <td height="60"></td>
                    </tr>
                    <tr class="visibleMobile">
                      <td height="40"></td>
                    </tr>
                    <tr>
                      <td>
                        <table
                          width="480"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          align="center"
                          class="fullPadding"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <table
                                  style={{ left: "60px" }}
                                  width="100%"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  class="col"
                                >
                                  <tbody>
                                    <tr class="visibleMobile">
                                      <td height="20"></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Open Sans, sans-serif",
                                          color: "#5b5b5b",
                                          lineHeight: "1",
                                          verticalAlign: "top",
                                          textAlign:
                                            props.lang === 0 ? "right" : "left",
                                        }}
                                      >
                                        <strong>
                                          {props.lang === 1
                                            ? "PAYMENT METHOD"
                                            : "אמצעי תשלום"}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="100%" height="10"></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Open Sans, sans-serif",
                                          color: "#5b5b5b",
                                          lineHeight: "1",
                                          verticalAlign: "top",
                                          textAlign:
                                            props.lang === 0 ? "right" : "left",
                                        }}
                                      >
                                        {props.lang === 1 ? (
                                          <>
                                            {paypal ? "Paypal" : "Credit Card"}
                                            <br /> Payer Email: {mail}
                                            <br /> Transaction ID:{" "}
                                          </>
                                        ) : (
                                          <>
                                            {paypal ? "PayPal" : "כרטיס אשראי"}
                                            <br />
                                            {mail} :איימיל
                                            <br />{" "}
                                            <a
                                              style={{
                                                color: "#81ecec",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {tid}
                                            </a>{" "}
                                            : מספר הזמנה
                                          </>
                                        )}

                                        <br />
                                      </td>
                                    </tr>
                                    <br />
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Open Sans, sans-serif",
                                          color: "#5b5b5b",
                                          lineHeight: "1",
                                          verticalAlign: "top",
                                          textAlign:
                                            props.lang === 0 ? "right" : "left",
                                        }}
                                      >
                                        <strong>
                                          {props.lang === 1
                                            ? "SHIPPING INFORMATION"
                                            : "פרטי משלוח"}
                                        </strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="100%" height="10"></td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Open Sans, sans-serif",
                                          color: "#5b5b5b",
                                          lineHeight: "1",
                                          verticalAlign: "top",
                                          textAlign:
                                            props.lang === 0 ? "right" : "left",
                                        }}
                                      >
                                        {props.lang === 1
                                          ? shipping
                                          : shipping === "Self Pickup"
                                            ? "איסוף עצמי"
                                            : shipping}
                                        <br />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>

                    <tr class="hiddenMobile">
                      <td height="60"></td>
                    </tr>
                    <tr class="visibleMobile">
                      <td height="30"></td>
                    </tr>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="0"
        align="center"
        class="fullTable"
      >
        <tr>
          <td>
            <table
              width="600"
              border="0"
              cellpadding="0"
              cellspacing="0"
              align="center"
              class="fullTable"
              bgcolor="#ffffff"
              style={{ borderRadius: "0 0 10px 10px" }}
            >
              <tr class="spacer">
                <td height="50"></td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td height="20"></td>
        </tr>
      </table>
    </div>
  );
};

export default Invoice;
