import * as React from "react";

const Globe = (props) => (
  <svg
    className="scale-50 sm:scale-100"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <path
      d="M33 1.831V16.05a37.934 37.934 0 0 0 10.575-1.775A38.243 38.243 0 0 0 33 1.831ZM46.362 15.406a39.925 39.925 0 0 1 3.613 15.669h14c-.244-7.969-3.4-15.2-8.438-20.675a39.912 39.912 0 0 1-9.175 5.006ZM45.481 13.606a38.176 38.176 0 0 0 8.638-4.643A31.873 31.873 0 0 0 34.08.15a40.236 40.236 0 0 1 11.4 13.456ZM49.975 33.075a39.79 39.79 0 0 1-4.438 17.356 39.562 39.562 0 0 1 8.844 4.507c5.713-5.594 9.331-13.307 9.594-21.863ZM47.975 33.075H33V48.1c3.681.094 7.244.675 10.612 1.694a37.784 37.784 0 0 0 4.363-16.719ZM31 33.075H16.025a37.862 37.862 0 0 0 4.356 16.719A40.253 40.253 0 0 1 31 48.1ZM33 62.319a38.188 38.188 0 0 0 9.606-10.732A38.023 38.023 0 0 0 33 50.1ZM33 31.075h14.975a37.733 37.733 0 0 0-3.506-14.994A39.928 39.928 0 0 1 33 18.05ZM31 18.05c-4-.1-7.85-.781-11.469-1.969a37.733 37.733 0 0 0-3.506 14.994H31ZM18.519 13.606A40.176 40.176 0 0 1 29.919.15 31.873 31.873 0 0 0 9.88 8.963a38.176 38.176 0 0 0 8.638 4.643ZM31 62.319V50.1c-3.325.087-6.55.6-9.606 1.487A38.103 38.103 0 0 0 31 62.32ZM44.556 52.212A40.245 40.245 0 0 1 34.081 64a31.873 31.873 0 0 0 18.781-7.669 37.564 37.564 0 0 0-8.306-4.119ZM19.444 52.206a38.117 38.117 0 0 0-8.306 4.119A31.88 31.88 0 0 0 29.918 64a40.19 40.19 0 0 1-10.474-11.794ZM14.025 33.075h-14c.262 8.556 3.881 16.269 9.594 21.862a39.712 39.712 0 0 1 8.843-4.512 39.827 39.827 0 0 1-4.437-17.35ZM31 1.831a38.243 38.243 0 0 0-10.575 12.444A37.934 37.934 0 0 0 31 16.05ZM14.025 31.075a39.816 39.816 0 0 1 3.612-15.669A40.02 40.02 0 0 1 8.462 10.4 31.86 31.86 0 0 0 .026 31.075Zm0 0"
      style={{
        fillRule: "nonzero",
        fill: "#d6b47c",
        fillOpacity: 1,
        strokeWidth: 0.00064,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        stroke: "#d6b47c",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      transform="scale(.625)"
    />
  </svg>
);
export default Globe;
