import React, { useState, useEffect } from "react";
import styles from "./index.module.css";

const Profile = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [tab, setTab] = useState(0);

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [name, setName] = useState("");
  const [last, setLast] = useState("");
  const [mail, setMail] = useState("");
  const [user, setUser] = useState("");
  const [country, setCountry] = useState(0);
  const [currency, setCurrency] = useState(0);

  const [purachseAlert, setPurcahseAlert] = useState(true);
  const [shipAlert, setShipAlert] = useState(false);
  const [deals, setDeals] = useState(true);

  const [submitEnabled, setSumbitEnabled] = useState(true);

  async function getData() {
    const response = await fetch("https://sunsouls.deviceid.io/profile", {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: "Bearer " + localStorage.getItem("sunsouls-token"),
      },
    });
    if (response.status === 200) {
      const content = await response.json();
      setMail(content.mail);
      setCountry(content.country);
      setCurrency(content.currency);
      setUser(content.user);
      setLast(content.name);
      setDeals(content.deal);
      setShipAlert(content.ship);
      setPurcahseAlert(content.buy);
    } else {
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    getData();
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main
      className={`w-[70%] absolute top-[70px] left-[15%] ${props.lang === 0 && "text-right"} ${styles.container}`}
      key={name}
    >
      <div className="py-6">
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-gray-900">
            {props.lang === 1 ? "Settings" : "הגדרות"}
          </h1>
        </div>

        <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
          <div className="w-full pb-1 overflow-x-auto">
            <div className="border-b border-gray-200">
              <nav
                className={`flex ${props.lang === 0 && "justify-end"} -mb-px space-x-10`}
              >
                <a
                  onClick={() => setTab(0)}
                  className={`cursor-pointer py-4 text-sm font-medium ${tab === 0 ? "text-indigo-600 border-indigo-600" : "text-gray-500"} border-b-2 transition-all duration-200 whitespace-nowrap`}
                >
                  {" "}
                  {props.lang === 1 ? "Profile" : "פרופיל"}{" "}
                </a>

                <a
                  onClick={() => setTab(1)}
                  className={`cursor-pointer py-4 text-sm font-medium ${tab === 1 ? "text-indigo-600 border-indigo-600" : "text-gray-500"} transition-all duration-200 border-b-2 whitespace-nowrap`}
                >
                  {" "}
                  {props.lang === 1 ? "Password" : "סיסמא"}{" "}
                </a>

                <a
                  onClick={() => setTab(2)}
                  className={`cursor-pointer py-4 text-sm font-medium ${tab === 2 ? "text-indigo-600 border-indigo-600" : "text-gray-500"} transition-all duration-200 border-b-2 whitespace-nowrap`}
                >
                  {" "}
                  {props.lang === 1 ? "Notifications" : "התראות"}{" "}
                </a>
              </nav>
            </div>
          </div>

          <div className="mt-6">
            <p className="text-base font-bold text-gray-900">
              {tab === 0
                ? props.lang === 1
                  ? "Profile"
                  : "פרופיל"
                : tab === 1
                  ? props.lang === 1
                    ? "Password"
                    : "סיסמא"
                  : props.lang === 1
                    ? "Notifications"
                    : "התראות"}
            </p>
            <p className="mt-1 text-sm font-medium text-gray-500">
              {tab === 0
                ? props.lang === 1
                  ? "Edit your profile information."
                  : "ערוך את המידע האישי שלך"
                : tab === 1
                  ? props.lang === 1
                    ? "Change your password"
                    : "ערוך את הסיסמא שלך"
                  : props.lang === 1
                    ? "Edit your notifications settings"
                    : "ערוך את הגדרות ההתראות שלך"}
            </p>
          </div>

          <form
            id="profileForm"
            className={`max-w-3xl mt-12 ${props.lang === 0 && "float-right"}`}
          >
            <div className="space-y-8">
              {tab === 0 ? (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1
                          ? "First & Last Name"
                          : "שם פרטי ומשפחה"}{" "}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <div>
                        <input
                          type="text"
                          name="lastname"
                          id="lastname"
                          for="profileForm"
                          placeholder=""
                          value={last}
                          onChange={(e) => setLast(e.target.value)}
                          className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                        />
                      </div>
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        שם פרטי ומשפחה{" "}
                      </label>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1
                          ? "Email Address"
                          : "כתובת איימיל"}{" "}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <input
                        type="email"
                        name=""
                        id=""
                        placeholder=""
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                      />
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        כתובת איימיל{" "}
                      </label>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <div className="sm:mt-px sm:pt-2">
                        <label
                          for=""
                          className="block text-sm font-bold text-gray-900"
                        >
                          {" "}
                          {props.lang === 1 ? "Username" : "שם משתמש"}{" "}
                        </label>
                        <p className="mt-1 text-sm font-medium text-gray-500">
                          {props.lang === 1
                            ? "You can change it later"
                            : "ניתן לשנות אותו תמיד"}
                        </p>
                      </div>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <div className="relative flex">
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder=""
                          value={user}
                          onChange={(e) => setUser(e.target.value)}
                          className="border flex-1 block w-full min-w-0 px-4 py-3 placeholder-gray-500 border-gray-300 rounded-none rounded-r-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                        />
                      </div>
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <div className="sm:mt-px sm:pt-2">
                        <label
                          for=""
                          className="block text-sm font-bold text-gray-900"
                        >
                          {" "}
                          שם משתמש{" "}
                        </label>
                        <p className="mt-1 text-sm font-medium text-gray-500">
                          ניתן לשנות אותו תמיד
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1 ? "Country" : "מדינה"}{" "}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <select
                        value={country}
                        onChange={(e) => setCountry(parseInt(e.target.value))}
                        className="block w-full py-3 pl-4 pr-10 border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                      >
                        <option value={0}>
                          {props.lang === 1 ? "United States" : "ארצות הברית"}
                        </option>
                        <option value={1}>
                          {props.lang === 1 ? "Israel" : "ישראל"}
                        </option>
                      </select>
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        מדינה{" "}
                      </label>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1 ? "Currency" : "מטבע"}{" "}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <select
                        value={currency}
                        onChange={(e) => setCurrency(parseInt(e.target.value))}
                        className="block w-full py-3 pl-4 pr-10 border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
                      >
                        <option value={0}>USD - $</option>
                        <option value={1}>ILS - $</option>
                      </select>
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        מטבע{" "}
                      </label>
                    )}
                  </div>
                </>
              ) : tab === 1 ? (
                <>
                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1 ? "Old Password" : "סיסמא ישנה"}
                        {""}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <input
                        type="password"
                        name=""
                        id=""
                        placeholder=""
                        value={oldPass}
                        onChange={(e) => setOldPass(e.target.value)}
                        className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                      />
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        סיסמא ישנה{""}
                      </label>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1 ? "New Password" : "סיסמא חדשה"} {""}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <input
                        type="password"
                        name=""
                        id=""
                        placeholder=""
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                        className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                      />
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        סיסמא חדשה{""}
                      </label>
                    )}
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                    {(props.lang === 1 || dimensions.width <= 640) && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        {props.lang === 1
                          ? "Confirm Password"
                          : "אישור סיסמא"}{" "}
                        {""}
                      </label>
                    )}
                    <div className="mt-2 sm:mt-0 sm:col-span-2">
                      <input
                        type="password"
                        name=""
                        id=""
                        placeholder=""
                        value={confirmPass}
                        onChange={(e) => setConfirmPass(e.target.value)}
                        className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600"
                      />
                    </div>
                    {props.lang === 0 && dimensions.width > 640 && (
                      <label
                        for=""
                        className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                      >
                        {" "}
                        אישור סיסמא {""}
                      </label>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`${props.lang === 0 && "justify-end"} relative flex items-center mt-2`}
                  >
                    <div className="flex items-center h-5">
                      {props.lang !== 0 && (
                        <input
                          type="checkbox"
                          name="profile"
                          id="profile"
                          className="border w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                          checked={purachseAlert}
                          onChange={() => setPurcahseAlert(!purachseAlert)}
                        />
                      )}
                    </div>

                    <div className="ml-3">
                      <label
                        for="profile"
                        className="text-sm font-medium text-gray-900"
                      >
                        {" "}
                        {props.lang === 1
                          ? "Paurchase Alerts"
                          : "התראות קנייה"}{" "}
                      </label>
                      <p className="text-gray-300">
                        {props.lang === 1
                          ? "Receive Email alerts every time your make a new purchase"
                          : "קבלו התראות איימיל על כל קנייה חדשה"}
                      </p>
                    </div>
                    {props.lang === 0 && (
                      <input
                        type="checkbox"
                        name="profile"
                        id="profile"
                        className="border w-4 ml-5 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                        checked={purachseAlert}
                        onChange={() => setPurcahseAlert(!purachseAlert)}
                      />
                    )}
                  </div>
                  <div
                    className={`${props.lang === 0 && "justify-end"} relative flex items-center mt-2`}
                  >
                    <div className="flex items-center h-5">
                      {props.lang === 1 && (
                        <input
                          type="checkbox"
                          name="profile"
                          id="profile"
                          className="border w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                          checked={shipAlert}
                          onChange={() => setShipAlert(!shipAlert)}
                        />
                      )}
                    </div>

                    <div className="ml-3">
                      <label
                        for="profile"
                        className="text-sm font-medium text-gray-900"
                      >
                        {" "}
                        {props.lang === 0
                          ? "קבלו התראות על שליחת הזמנה"
                          : "Shipping Alerts"}{" "}
                      </label>
                      <p className="text-gray-300">
                        {props.lang === 0
                          ? "קבלו התראת איימיל עם מספר המעקב שלכם כשההזמנה נשלחה"
                          : "Receive Email alert with your tracking number when the order shippes"}
                      </p>
                    </div>
                    {props.lang === 0 && (
                      <input
                        type="checkbox"
                        name="profile"
                        id="profile"
                        className="border w-4 h-4 ml-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                        checked={shipAlert}
                        onChange={() => setShipAlert(!shipAlert)}
                      />
                    )}
                  </div>
                  <div
                    className={`${props.lang === 0 && "justify-end"} relative flex items-center mt-2`}
                  >
                    <div className="flex items-center h-5">
                      {props.lang === 1 && (
                        <input
                          type="checkbox"
                          name="profile"
                          id="profile"
                          className="border w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                          checked
                        />
                      )}
                    </div>

                    <div className="ml-3">
                      <label
                        for="profile"
                        className="text-sm font-medium text-gray-900"
                      >
                        {" "}
                        {props.lang === 0
                          ? "התראות על מבצעים ומוצרים חדשים"
                          : "Deals and New Profucts Alerts"}{" "}
                      </label>
                      <p className="text-gray-300">
                        {props.lang === 0
                          ? "קבלו התראות איימיל על כל מבצע ומוצר חדש שיוצא"
                          : "Recevie Email alerts for new deals and product releases"}
                      </p>
                    </div>
                    {props.lang === 0 && (
                      <input
                        type="checkbox"
                        name="profile"
                        id="profile"
                        className="border ml-5 w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                        checked={deals}
                        onChange={() => setDeals(!deals)}
                      />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="mt-6 sm:mt-12">
              <button
                disabled={!submitEnabled}
                onClick={async (e) => {
                  e.preventDefault();
                  setSumbitEnabled(false);
                  if (tab === 0) {
                    const response = await fetch(
                      "https://sunsouls.deviceid.io/profile",
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "text/plain",
                          Authorization:
                            "Bearer " + localStorage.getItem("sunsouls-token"),
                        },
                        body: JSON.stringify({
                          mail,
                          name: last,
                          country,
                          user,
                          currency,
                        }),
                      },
                    );

                    if (response.status === 200) {
                      props.setAlertType(true);
                      props.setAlertText(
                        props.lang === 0
                          ? "הפרופיל עודכן"
                          : "Profile Updated !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "שגיאה, נסו שוב"
                          : "Unexpected Error !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    }
                    setSumbitEnabled(true);
                  } else if (tab === 1) {
                    const re =
                      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;
                    if (!re.test(newPass)) {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "סיסמא חדשה צריכה להיות 8 תווים, אות גדולה וסימן"
                          : "New password should be at least 8 chars, upper case and a special char",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                      setSumbitEnabled(true);
                      return;
                    }
                    if (oldPass !== confirmPass) {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "סיסמאות לא זהות"
                          : "Passwords do not match !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                      setSumbitEnabled(true);
                      return;
                    }
                    const response = await fetch(
                      "https://sunsouls.deviceid.io/pass",
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "text/plain",
                          Authorization:
                            "Bearer " + localStorage.getItem("sunsouls-token"),
                        },
                        body: JSON.stringify({
                          old: oldPass,
                          new: newPass,
                        }),
                      },
                    );

                    if (response.status === 200) {
                      setNewPass("");
                      setOldPass("");
                      setConfirmPass("");
                      props.setAlertType(true);
                      props.setAlertText(
                        props.lang === 0
                          ? "הסיסמא שונתה"
                          : "Password Updated !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else if (response.status === 403) {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "משתמש גוגל לא יכול לשנות סיסמא"
                          : "Google users cannot change password !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "שגיאה, נסו שוב"
                          : "Unexpected Error !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    }
                    setSumbitEnabled(true);
                  } else {
                    const response = await fetch(
                      "https://sunsouls.deviceid.io/settings",
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "text/plain",
                          Authorization:
                            "Bearer " + localStorage.getItem("sunsouls-token"),
                        },
                        body: JSON.stringify({
                          deals,
                          buy: purachseAlert,
                          ship: shipAlert,
                        }),
                      },
                    );

                    if (response.status === 200) {
                      props.setAlertType(true);
                      props.setAlertText(
                        props.lang === 0
                          ? "ההגדרות עודכנו"
                          : "Settings Updated !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    } else {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "שגיאה, נסו שוב"
                          : "Unexpected Error !",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    }
                    setSumbitEnabled(true);
                  }
                }}
                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
              >
                {props.lang === 1 ? "Update" : "עדכן"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Profile;
