import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { OpenSingle, WaveText } from "../../assets";
import ReactPlayer from "react-player";
import { OpenVideo } from "../../assets";

const Welcome = (props) => {
  const { scrollY } = useScroll();
  const [scrollYProgress, setProggress] = useState(0);
  const [small, setSmall] = useState(false);

  const videoRef = React.createRef();

  useMotionValueEvent(scrollY, "change", (latest) => {
    setProggress(latest);
  });

  useEffect(() => {
    if (props.width >= 600) setSmall(false);
    else setSmall(true);
  }, [props.width]);

  useEffect(() => {
    try {
      // videoRef.current.play();
    } catch (e) {}
  }, []);

  return (
    <div className={`${styles.container}`}>
      <video
        ref={videoRef}
        autoplay="true"
        muted="true"
        webkit-playsinline="true"
        playsinline="true"
        loop="true"
        width="100%"
        className="absolute z-0"
        key={props.width}
      >
        <source
          src={props.width > 768 ? OpenVideo : OpenSingle}
          type="video/mp4"
        />
      </video>
      {/*
      <ReactPlayer
        className="absolute z-1"
        url={OpenVideo}
        height="30%"
        width="100%"
        playing={true}
        controls={false}
        pip={true}
        playIcon={<></>}
        loop={true}
      /> */}
      <motion.svg
        initial={{ y: -1000 }}
        animate={{ y: -scrollYProgress * 2 - 2 }}
        transition={{
          duration: 0.7,
          type: "spring",
          stiffness: 50,
          damping: 20,
        }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
      >
        <path
          fill="white"
          fill-opacity="1"
          d="M0,96L34.3,122.7C68.6,149,137,203,206,229.3C274.3,256,343,256,411,224C480,192,549,128,617,96C685.7,64,754,64,823,80C891.4,96,960,128,1029,160C1097.1,192,1166,224,1234,240C1302.9,256,1371,256,1406,256L1440,256L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
        ></path>
      </motion.svg>
      <motion.div
        className={`w-full flex flex-col align-center items-center `}
        initial={{ y: -1100 }}
        animate={{
          y: props.width < 768 ? "50%" : -22 + props.width / 100 + "vh",
        }}
        transition={{
          duration: 0.75,
          type: "spring",
          stiffness: 100,
          damping: 40,
        }}
      >
        <motion.svg
          animate={{ y: scrollYProgress * 2 + 2 }}
          transition={{
            duration: 0.7,
            type: "spring",
            stiffness: 50,
            damping: 20,
          }}
          className="relative sm:top-[30vh] top-[0vh] w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="white"
            fill-opacity="1"
            d="M0,96L34.3,122.7C68.6,149,137,203,206,229.3C274.3,256,343,256,411,224C480,192,549,128,617,96C685.7,64,754,64,823,80C891.4,96,960,128,1029,160C1097.1,192,1166,224,1234,240C1302.9,256,1371,256,1406,256L1440,256L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
          ></path>
        </motion.svg>
        <div
          className={`bg-white relative top-[0vh] sm:top-[30vh] h-[1500px] w-full `}
        />
      </motion.div>
    </div>
  );
};

export default Welcome;
