import React, { useState } from "react";

const ConfirmPhone = (props) => {
  const [code, setCode] = useState("");
  const [change, setChange] = useState(false);

  return (
    <section className="py-2">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900">
              {!change
                ? props.lang === 0
                  ? "אמתו את מספר הטלפון שלכם"
                  : "Verify Your Phone Number"
                : props.lang === 0
                  ? "שנו את המספר שלכם"
                  : "Change Your Phone Number"}
            </h1>
            <p className="mt-4 text-sm font-medium text-gray-500">
              {!change
                ? props.lang === 0
                  ? "שלחנו הודעת סמס למספר הטלפון שלכם. אנא אפשרו מספר רגעים להודעה להגיע."
                  : `We've sent an SMS with a verification code to your phone number,
              Please enter it below. Please allow a few moments for the SMS to
              arrive`
                : props.lang === 0
                  ? "אנא הכניסו את מספר הטלפון הנכון שלכם כולל קידומת"
                  : "Please enter your correct phone number including country code"}
            </p>
          </div>

          <form className="mt-4">
            <div className={`space-y-4 ${props.lang === 0 && "text-right"}`}>
              <div>
                <label for="" className={`text-sm font-bold text-gray-900`}>
                  {" "}
                  {!change
                    ? props.lang === 0
                      ? "קוד אימות"
                      : "Verification Code"
                    : props.lang === 0
                      ? "מספר טלפון"
                      : "Phone Number"}{" "}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    placeholder={
                      !change
                        ? props.lang === 0
                          ? "קוד אימות "
                          : "5-Digit Verification Code"
                        : props.lang === 0
                          ? "מספר טלפון"
                          : "Phone Number"
                    }
                    value={code}
                    className={`text-center border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 `}
                  />
                </div>
              </div>

              <div>
                <div
                  className="cursor-pointer inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none"
                  onClick={async (e) => {
                    const response = await fetch(
                      `https://sunsouls.deviceid.io/${!change ? "verify" : "changePhone"}`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "text/plain",
                          Authorization:
                            "Bearer " + localStorage.getItem("sunsouls-token"),
                        },
                        body: JSON.stringify({ code }),
                      },
                    );
                    const content = await response.text();
                    if (response.status === 200) {
                      if (change) {
                        setCode("");
                        setChange(false);
                        props.setAlertType(true);
                        props.setAlertText(
                          props.lang === 0
                            ? "מספר הטלפון שונה בהצלחה"
                            : "Phone number successfully changed",
                        );
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                      } else window.location.replace("/");
                    } else {
                      props.setAlertType(false);
                      props.setAlertText(
                        props.lang === 0
                          ? "שגיאה ! אנא נסו שוב.."
                          : "Unexpected error ! Please try again..",
                      );
                      props.setShowAlert(true);
                      setTimeout(() => props.setShowAlert(false), 3000);
                    }
                  }}
                >
                  {!change
                    ? props.lang === 0
                      ? "אימות"
                      : "Verify"
                    : props.lang === 0
                      ? "שינוי מספר"
                      : "Change Number"}
                </div>
              </div>
            </div>
          </form>

          <div className="mt-6 text-center">
            <p className="text-sm font-medium text-gray-900">
              {!change &&
                (props.lang === 0
                  ? "מספר טלפון שגוי ?"
                  : "Wrong phone number?")}{" "}
              <a
                onClick={() => setChange(!change)}
                title=""
                className="font-bold hover:underline cursor-pointer"
              >
                {" "}
                {!change
                  ? props.lang === 0
                    ? "שינוי מספר"
                    : "Change Number"
                  : props.lang === 0
                    ? "חזרו לאימות"
                    : "Return to Verification"}{" "}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmPhone;
