import {
  Featured,
  Navbar,
  Welcome,
  Checkout,
  History,
  Details,
  Footer,
  Confirm,
  Settings,
  Category,
  Login,
  Register,
  Alert,
  ConfirmPhone,
  Omri,
} from "./components";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Product,
  Invoice,
  About,
  Terms,
  Profile,
  Contact,
  Privacy,
  Thanks,
} from "./containers";
import React, { useState, useEffect } from "react";
import "./index.css";

function App() {
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [refreshCart, setRefreshCart] = useState(false);
  const [lang, setLang] = useState(0);
  const [loading, setLoading] = useState(false);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  async function getDetails(token) {
    const response = await fetch("https://sunsouls.deviceid.io/init", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const content = await response.json();
    if (response.status === 200) {
      localStorage.setItem("sunsouls-logged", 1);
      localStorage.setItem("sunsouls-name", content.name);
      localStorage.setItem("sunsouls-mail", content.mail);
      localStorage.setItem("sunsouls-currency", content.currency);
      localStorage.setItem("sunsouls-country", content.country);
      setLang(content.country === 1 ? 0 : 1);
      content.phone !== undefined &&
        localStorage.setItem("sunsouls-phone", content.phone);
      // localStorage.setItem("sunsouls-cart", JSON.stringify(content.cart));
    } else {
      localStorage.setItem("sunsouls-logged", false);
    }
  }

  useEffect(() => {
    /*
    var ua = navigator.userAgent || navigator.vendor;
    var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
    var str = navigator.userAgent;
    var i = str.indexOf("Instagram");
    if (isInstagram) {
      if (/iPad|iPhone|iPod/.test(ua)) {
        window.location.href = "googlechrome://sunsouls.store";
        return;
      }
      window.location.href = "intent:https://sunsouls.store#Intent;end";
      return;
    }
     */
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    let resizeWindow = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", resizeWindow);
    const stored = localStorage.getItem("sunsouls-token");
    if (typeof stored === "string" && stored.length > 10) {
      getDetails(stored);
    }
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <GoogleOAuthProvider clientId="839115929820-qjgevpbmiiis8vo6qslih8fv1adi7p0i.apps.googleusercontent.com">
      <div className="App">
        {loading && (
          <div className="fixed top-0 left-0 loader-container w-full h-full flex items-center justify-center">
            <div class="loader z-10">
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="40px"
                height="40px"
                viewBox="0 0 40 40"
                enable-background="new 0 0 40 40"
                fill="#d6b47c"
              >
                <path
                  opacity="0.2"
                  fill="#ff6700"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path
                  fill="#d6b47c"
                  d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z"
                >
                  <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
        )}
        <Navbar refresh={refreshCart} lang={lang} setLang={setLang} />
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <div className="absolute top-[70px] w-full main-window overflow-hidden flex flex-col items-center align-center">
                  <div className="height-[30px] bg-[#d6b47c] fixed top-0 left-0 w-full flex items-center justify-center z-[30]">
                    <p className="text-white">
                      {lang === 0
                        ? "! שליח עד הבית חינם בקנייה של 3 חולצות"
                        : "Buy 3 Shirts or More to Enjoy Free Shipping !"}
                    </p>
                  </div>
                  <Welcome width={width} />
                  <Omri lang={lang} />
                  <Featured lang={lang} />
                  <button
                    onClick={() => (window.location.href = "/shirts")}
                    className="inline-flex items-center justify-center mt-[80px] w-[60%] px-6 py-4 text-sm font-bold text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
                  >
                    {lang === 0
                      ? "לצפייה בכל הקולקצייה"
                      : "View entire collection"}
                  </button>
                  <Footer lang={lang} />
                </div>
              }
            />
            <Route path="/about" element={<About lang={lang} />} />
            <Route path="/contact" element={<Contact lang={lang} />} />
            <Route
              path="/profile"
              element={
                <Profile
                  setShowAlert={setShowAlert}
                  setAlertType={setAlertType}
                  setAlertText={setAlertText}
                  lang={lang}
                />
              }
            />
            <Route
              path="/product"
              element={
                <Product
                  refresh={refreshCart}
                  setRefresh={setRefreshCart}
                  setAlertText={setAlertText}
                  setAlertType={setAlertType}
                  setShowAlert={setShowAlert}
                  lang={lang}
                  setLoading={setLoading}
                />
              }
            />
            <Route path="/thanks" element={<Thanks lang={lang} />} />
            <Route
              path="/checkout"
              element={
                <Checkout
                  refresh={refreshCart}
                  setRefresh={setRefreshCart}
                  lang={lang}
                  setAlertText={setAlertText}
                  setAlertType={setAlertType}
                  setShowAlert={setShowAlert}
                  width={width}
                />
              }
            />
            <Route
              path="/history"
              element={<History lang={lang} setLoading={setLoading} />}
            />
            <Route
              path="/order"
              element={<Details lang={lang} setLoading={setLoading} />}
            />
            <Route
              path="/order-confirmation"
              element={<Confirm lang={lang} />}
            />
            <Route path="/settings" element={<Settings lang={lang} />} />
            <Route
              path="/shirts"
              element={<Category lang={lang} setLoading={setLoading} />}
            />
            <Route path="/invoice" element={<Invoice lang={lang} />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<Privacy />} />
            <Route
              path="/login"
              element={
                <Login
                  setShowAlert={setShowAlert}
                  setAlertType={setAlertType}
                  setAlertText={setAlertText}
                  lang={lang}
                />
              }
            />
            <Route
              path="/register"
              element={
                <Register
                  setShowAlert={setShowAlert}
                  setAlertType={setAlertType}
                  setAlertText={setAlertText}
                  lang={lang}
                />
              }
            />
            <Route
              path="/confirm"
              element={
                <ConfirmPhone
                  lang={lang}
                  setShowAlert={setShowAlert}
                  setAlertType={setAlertType}
                  setAlertText={setAlertText}
                />
              }
            />
          </Routes>
        </BrowserRouter>
        <Alert show={showAlert} type={alertType} val={alertText} lang={lang} />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
