import React, { useEffect, useState } from "react";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import styles from "./index.module.css";

const Login = (props) => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [rem, setRem] = useState(false);
  const [forgot, setForgot] = useState(false);

  const login = useGoogleLogin({
    redirect_uri: "https://sunsouls.store",
    onSuccess: async (codeResponse) => {
      const response = await fetch(
        "https://sunsouls.deviceid.io/google_login",
        {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: JSON.stringify({
            token: codeResponse.access_token,
          }),
        },
      );
      const content = await response.text();
      if (response.status === 200) {
        localStorage.setItem("sunsouls-token", content);
        window.location.replace("/");
      } else if (response.status === 406) {
        props.setAlertType(false);
        props.setAlertText("User not found ! Please register.");
        props.setShowAlert(true);
        setTimeout(() => props.setShowAlert(false), 3000);
      }
    },
  });

  return (
    <section
      className={`${styles.container} absolute top-[75px] w-full h-full left-0 bg-white`}
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center">
            <h1 className="mt-12 text-3xl font-bold text-gray-900">
              {forgot
                ? props.lang === 0
                  ? "איפוס סיסמא"
                  : "Reset Password"
                : props.lang === 0
                  ? "התחברות"
                  : "Login to Sunsouls"}
            </h1>
            <p className="mt-4 text-sm font-medium text-gray-500">
              {forgot
                ? props.lang === 0
                  ? "תקלנו הנחיות לאיפוס הסיסמא לכתובת האיימיל שלכם"
                  : "You'll receive instuctions to reset your password to your email address"
                : props.lang === 0
                  ? "ברוכים הבאים !"
                  : "WELCOME TO SUNSOULS !"}
            </p>
          </div>
          {!forgot && (
            <>
              <div className="mt-12">
                <button
                  type="button"
                  onClick={() => login()}
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                >
                  <img
                    className="w-5 h-5 mr-2"
                    src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg"
                    alt=""
                  />
                  {props.lang === 0 ? "התחברו עם גוגל" : "Sign in with Google"}
                </button>
              </div>
              <div className="relative mt-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>

                <div className="relative flex justify-center">
                  <span className="px-2 text-sm text-gray-400 bg-white">
                    {" "}
                    {props.lang === 0 ? "או" : "or"}{" "}
                  </span>
                </div>
              </div>
            </>
          )}
          {/*<div>
          <GoogleLogin
            redirect_uri="sunsouls.store"
            onSuccess={async (codeResponse) => {
              const response = await fetch(
                "https://sunsouls.deviceid.io/google_login",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "text/plain",
                  },
                  body: JSON.stringify({
                    token: codeResponse.access_token,
                  }),
                },
              );
              const content = await response.text();
              if (response.status === 200) {
                localStorage.setItem("sunsouls-token", content);
                window.location.replace("/");
              } else if (response.status === 406) {
                props.setAlertType(false);
                props.setAlertText("User not found ! Please register.");
                props.setShowAlert(true);
                setTimeout(() => props.setShowAlert(false), 3000);
              }
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          /></div>*/}

          <form className="mt-4">
            <div className="space-y-4">
              <div
                className={`flex items-center ${props.lang === 0 ? "justify-end" : "justify-start"}`}
              >
                <label
                  for=""
                  className={`text-sm font-bold text-gray-900 text-right`}
                >
                  {" "}
                  {props.lang === 0 ? "איימיל" : "Email"}{" "}
                </label>
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  name=""
                  id=""
                  onChange={(e) => setUser(e.target.value)}
                  placeholder={
                    props.lang === 0
                      ? "שם משתמש / כתובת איימיל"
                      : "Username / Email address"
                  }
                  value={user}
                  className={`border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${props.lang === 0 && "text-right"}`}
                />
              </div>

              <div>
                {!forgot && (
                  <>
                    <div
                      className={`flex items-center ${props.lang === 0 ? "justify-end" : "justify-between"}`}
                    >
                      <label for="" className="text-sm font-bold text-gray-900">
                        {" "}
                        {props.lang === 0 ? "סיסמא" : "Password"}{" "}
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        type="password"
                        name=""
                        id=""
                        onChange={(e) => setPass(e.target.value)}
                        placeholder={
                          props.lang === 0
                            ? "סיסמא (לפחות 8 תווים)"
                            : "Password (min. 8 character)"
                        }
                        value={pass}
                        className={`border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 ${props.lang === 0 && "text-right"}`}
                      />
                    </div>
                  </>
                )}
                <a
                  onClick={() => setForgot(!forgot)}
                  title=""
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-700 flex items-center justify-end mt-[20px]"
                >
                  {" "}
                  {forgot
                    ? props.lang === 0
                      ? "חזרה להתחברות"
                      : "Back to Login"
                    : props.lang === 0
                      ? "שכחתי סיסמא"
                      : "Forgot Password?"}{" "}
                </a>
              </div>

              {!forgot && (
                <div
                  className={`relative flex items-center ${props.lang === 0 && "justify-end"}`}
                >
                  {props.lang === 1 && (
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        checked={rem}
                        onChange={() => setRem(!rem)}
                        name="remember-password"
                        id="remember-password"
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                      />
                    </div>
                  )}

                  <div className="ml-3">
                    <label
                      for="remember-password"
                      className="text-sm font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "זכור אותי" : "Remember Me"}{" "}
                    </label>
                  </div>
                  {props.lang === 0 && (
                    <div className="flex items-center h-5">
                      <input
                        type="checkbox"
                        checked={rem}
                        onChange={() => setRem(!rem)}
                        name="remember-password"
                        id="remember-password"
                        className="w-4 h-4 ml-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600"
                      />
                    </div>
                  )}
                </div>
              )}

              <div>
                <div
                  onClick={async () => {
                    if (forgot) {
                    } else {
                      if (user.length < 3 || user.length > 32) {
                        props.setAlertType(false);
                        props.setAlertText(
                          "Username should be between 3 and 32 charachters.",
                        );
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                        return;
                      }
                      if (pass.length < 8 || pass.length > 64) {
                        props.setAlertType(false);
                        props.setAlertText(
                          "Password should be between 8 and 64 charachters.",
                        );
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                        return;
                      }
                      const response = await fetch(
                        "https://sunsouls.deviceid.io/login",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "text/plain",
                          },
                          body: JSON.stringify({
                            user,
                            pass,
                            rem,
                          }),
                        },
                      );
                      const content = await response.text();
                      if (response.status === 200) {
                        localStorage.setItem("sunsouls-token", content);
                        window.location.replace("/");
                      } else if (response.status === 406) {
                        props.setAlertType(false);
                        props.setAlertText(content);
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                      } else if (response.status === 501) {
                        props.setAlertType(false);
                        props.setAlertText(
                          "Error while sending SMS, please try again later.",
                        );
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                      } else if (response.status === 403) {
                        props.setAlertType(false);
                        props.setAlertText("User not found.");
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                      } else {
                        props.setAlertType(false);
                        props.setAlertText(
                          "Unexpected error, please try again later.",
                        );
                        props.setShowAlert(true);
                        setTimeout(() => props.setShowAlert(false), 3000);
                      }
                    }
                  }}
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 cursor-pointer"
                >
                  {forgot
                    ? props.lang === 0
                      ? "איפוס סיסמא"
                      : "Reset Password"
                    : props.lang === 0
                      ? "התחברות"
                      : "Sign in"}
                </div>
              </div>
            </div>
          </form>
          <div className="mt-6 text-center">
            {!forgot && (
              <p className="text-sm font-medium text-gray-900">
                {props.lang === 0
                  ? "אין לכם חשבון עדיין"
                  : "Don't have an account?"}
                {props.lang === 0 && " ?"}
                <a
                  href="/register"
                  title=""
                  className="font-bold hover:underline"
                >
                  {" "}
                  {props.lang === 0 ? "הירשמו עכשיו" : "Sign up now"}{" "}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
