import React, { useState } from "react";
import styles from "./index.module.css";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

const IssueModal = (props) => {
  const [value, setValue] = useState(5);
  const [hover, setHover] = useState(-1);
  const [review, setReview] = useState("");

  const labels = {
    0: props.lang === 0 ? "חסר תועלת" : "Useless",
    1: props.lang === 0 ? "עלוב" : "Poor",
    2: props.lang === 0 ? "בסדר" : "Ok",
    3: props.lang === 0 ? "טוב" : "Good",
    4: props.lang === 0 ? "מצויין" : "Excellent",
    5: props.lang === 0 ? "מצויין+" : "Excellent+",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  return (
    <div
      class={`fixed top-0 left-0 ${styles.container} ${styles.render} ${props.show && styles.show} `}
    >
      <div
        class={`flex items-center justify-center w-full h-full px-4 py-5 sm:p-6 ${props.lang === 0 && "text-right"}`}
      >
        <div class="w-full max-w-sm bg-white shadow-lg rounded-xl">
          <div class="px-4 py-5 sm:p-6">
            <p class="mt-5 text-xl font-bold text-gray-900">{props.title}</p>
            <p class="mt-3 text-sm font-medium text-gray-500">{props.text}</p>
            <br />
            <div className="mt-2 sm:mt-0 sm:col-span-2">
              <select
                className={`${props.lang === 0 && styles.select} text-right block w-full py-3 pl-4 pr-10 border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm`}
              >
                <option>
                  {props.lang === 0
                    ? "המוצר עוד לא הגיע"
                    : "Item hasn't arrived"}
                </option>
                <option>
                  {props.lang === 0
                    ? "יש לי בעיה עם המוצר"
                    : "I have a problem with the item"}
                </option>
                <option>
                  {props.lang === 0
                    ? "אני רוצה להחזיר את המוצר"
                    : "I want to return my item"}
                </option>
                <option>{props.lang === 0 ? "אחר" : "Other"}</option>
              </select>
            </div>
            <div class="py-8 bg-white">
              <div class="mx-auto  max-w-7xl">
                <div class="max-w-xl mx-auto">
                  <div>
                    <label for="" class="block text-sm font-bold text-gray-900">
                      {" "}
                      {props.lang === 0 ? "תוכן" : "Message"}{" "}
                    </label>
                    <div class="mt-2">
                      <textarea
                        name=""
                        id=""
                        placeholder={
                          props.lang === 0
                            ? "תארו את הבעיה"
                            : "Describe your issue"
                        }
                        onChange={(e) => setReview(e.target.value)}
                        value={review}
                        rows="4"
                        className={`${props.lang === 0 && "text-right"} border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg resize-y focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600`}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center mt-8 space-x-4">
              <button
                type="button"
                onClick={() => props.setShow(false)}
                class="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
              >
                {props.lang === 0 ? "בטל" : "Cancel"}
              </button>

              <button
                type="button"
                onClick={async () => {
                  if (review.length <= 0 || review.length > 512) {
                    // show alert
                    return;
                  }
                  const response = await fetch(
                    "https://sunsouls.deviceid.io/review",
                    {
                      method: "PUT",
                      headers: {
                        "Content-Type": "text/plain",
                        Authorization:
                          "Bearer " + localStorage.getItem("sunsouls-token"),
                      },
                      body: JSON.stringify({
                        val: value,
                        text: review,
                        prod: props.prod,
                      }),
                    },
                  );
                  props.setShow(false);
                  if (response.status === 200) {
                    props.setReview({
                      date: new Date().toISOString(),
                      name: localStorage.getItem("sunsouls-name"),
                      rating: value,
                      val: review,
                      user: "",
                      _id: "",
                    });
                  } else if (response.status === 401) {
                    props.setAlertType(false);
                    props.setAlertText(
                      props.lang === 0
                        ? "אנא התחברו שוב"
                        : "Please login in again",
                    );
                    props.setShowAlert(true);
                    setTimeout(() => props.setShowAlert(false), 3000);
                  } else {
                    props.setAlertType(false);
                    props.setAlertText(
                      props.lang === 0
                        ? "שגיאה ! נסו שוב.."
                        : "Unexpected Error !",
                    );
                    props.setShowAlert(true);
                    setTimeout(() => props.setShowAlert(false), 3000);
                  }
                }}
                class="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-red-500 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:bg-red-700"
              >
                {props.button}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueModal;
