import React, { useEffect, useState } from "react";
import { currencyByLang } from "../../constants";
import styles from "./index.module.css";

const Category = (props) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    props.setLoading(true);
    const response = await fetch("https://sunsouls.deviceid.io/shirts", {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: "Bearer " + localStorage.getItem("sunsouls-token"),
      },
    });

    if (response.status === 200) {
      const content = await response.json();
      console.log(content);
      setItems(content);
      props.setLoading(false);
    } else {
      props.setLoading(false);
    }
  }

  return (
    <section
      className={`py-12 sm:py-16 lg:py-20 bg-gray-50 w-full ${styles.container}`}
    >
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl">
            {props.lang === 1 ? "Sun Shirts" : "חולצות שמש"}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-5 mt-12 text-center sm:mt-16 lg:grid-cols-4 lg:gap-0 cursor-pointer">
          {items.map((item) => (
            <div
              className="flex flex-col border border-gray-200 cursor-pointer"
              onClick={() => (window.location.href = "/product?id=" + item._id)}
            >
              <div className="flex-shrink-0">
                <img
                  className="object-contain mx-auto mix-blend-multiply cursor-pointer"
                  src={`data:image/${item.images[0].type};base64, ${item.images[0].data}`}
                  alt=""
                />
              </div>
              <div className="flex flex-col items-center flex-1 px-4 pb-8">
                <p className="text-xs font-bold tracking-widest text-gray-400 uppercase">
                  {props.lang === 1 ? "Sunshirts" : "חולצת שמש"}
                </p>
                <h3 className="text-base font-bold text-gray-900 mt-2.5">
                  <a href="#" title="" className="">
                    {" "}
                    {item.name[props.lang === 1 ? "EN" : "HE"]}{" "}
                  </a>
                </h3>
                <p className="flex-1 mt-3 text-base font-bold text-gray-500">
                  {(item.price * (item.discount / 100)) /
                    (props.lang === 0 ? 1 : 3.5)}
                  {currencyByLang.get(props.lang === 0 ? "HE" : "EN")}
                </p>
                <button
                  type="button"
                  onClick={() =>
                    (window.location.href = "/product?id=" + item._id)
                  }
                  className="inline-flex items-center justify-center px-6 py-3 mt-6 text-sm font-bold text-white transition-all duration-200 bg-[#d6b47c] rounded-md sm:px-12 focus:outline-none"
                >
                  {props.lang === 1 ? "Buy Now" : "קנה עכשיו"}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Category;
