import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import OutsideAlerter from "./OutsideAlerter";
import { Logo, Menu, Globe, User, Bell, Bag, HE, US } from "../../assets";
import { currencyByLang } from "../../constants";

const Navbar = (props) => {
  const [menu_active, set_menu_active] = useState(false);
  const [outside_click, set_outside_click] = useState(false);
  const [card_active, set_card_active] = useState(false);
  const [hidden, set_hidden] = useState(false);
  const [cart, setCart] = useState([]);
  const [lang_active, set_lang_active] = useState(false);
  const [profile_active, set_profile_active] = useState(false);
  const [profile, setProfile] = useState(0);

  function resetMenus() {
    set_menu_active(false);
    set_card_active(false);
    set_outside_click(false);
    set_lang_active(false);
    set_profile_active(false);
  }

  function parseCart(index) {
    try {
      const items = JSON.parse(localStorage.getItem("sunsouls-cart"));
      items.splice(index, 1);
      setCart(items);
      if (items.length === 0) localStorage.removeItem("sunsouls-cart");
      else localStorage.setItem("sunsouls-cart", items);
    } catch (e) {}
  }

  useEffect(() => {
    resetMenus();
  }, [outside_click]);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/") setProfile(1);
    else if (pathname === "/shirts") setProfile(2);
    else if (pathname === "/about") setProfile(3);
  }, []);

  useEffect(() => {
    /*
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/register" ||
      window.location.pathname === "/confirm"
    )
      set_hidden(true);
      */
    const language = localStorage.getItem("sunsouls-lang");
    try {
      const casted = parseInt(language);
      if (typeof casted === "number" && casted > 0 && casted < 10)
        props.setLang(casted);
    } catch (e) {
      localStorage.setItem("sunsouls-lang", 1);
    }
  }, []);

  useEffect(() => {
    try {
      const items = JSON.parse(localStorage.getItem("sunsouls-cart"));
      if (items === null || items === undefined || !Array.isArray(items))
        return;
      setCart(items);
    } catch (e) {
      localStorage.removeItem("sunsouls-cart");
    }
  }, [props.refresh]);

  return hidden ? (
    <></>
  ) : (
    <OutsideAlerter set_outside_click={set_outside_click} className="w-full">
      <div
        className={`${styles.render} ${profile_active && styles.show} ${styles.container} flex-col align-center justify-between shadow absolute z-50 md:right-[23vw] right-[10vw] top-[90px] w-[180px] h-[150px] bg-white inline-flex p-2 -m-2 text-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900`}
      >
        <div
          className={`cursor-pointer flex w-full h-[50px] justify-center items-center`}
          onClick={() => {
            window.location.href = "/profile";
          }}
        >
          <p>{props.lang === 1 ? "Profile" : "פרופיל"}</p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[50px] justify-center items-center`}
          onClick={() => {
            window.location.href = "/history";
          }}
        >
          <p>{props.lang === 1 ? "Orders" : "הזמנות"}</p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[50px] justify-center items-center`}
          onClick={() => {
            localStorage.removeItem("sunsouls-logged");
            localStorage.removeItem("sunsouls-token");
            window.location.href = "/login";
          }}
        >
          <p>{props.lang === 1 ? "Logout" : "התנתק"}</p>
        </div>
      </div>

      <div
        className={`${styles.render} ${menu_active && styles.show} flex-col align-center items-center  shadow absolute z-50 md:left-[17vw] left-[10vw] top-[90px] w-[180px] h-[150px] bg-white inline-flex p-2 -m-2 text-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900`}
      >
        <div
          className={`cursor-pointer flex w-full h-[33%] justify-evenly items-center ${props.profile === 1 && "bg-zinc-50"}`}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <p className="text-[#d6b47c]">{props.lang === 1 ? "Home" : "בית"}</p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[33%] justify-evenly items-center ${props.profile === 2 && "bg-zinc-50"}`}
          onClick={() => {
            window.location.href = "/shirts";
          }}
        >
          <p className="text-[#d6b47c]">
            {props.lang === 1 ? "Shirts" : "חולצות"}
          </p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[33%] justify-evenly items-center ${props.profile === 3 && "bg-zinc-50"}`}
          onClick={() => {
            window.location.href = "/contact";
          }}
        >
          <p className="text-[#d6b47c]">
            {props.lang === 1 ? "Contact" : "יצירת קשר"}
          </p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[33%] justify-evenly items-center ${props.profile === 3 && "bg-zinc-50"}`}
          onClick={() => {
            window.location.href = "/about";
          }}
        >
          <p className="text-[#d6b47c]">
            {props.lang === 1 ? "About" : "אודות"}
          </p>
        </div>
      </div>

      <div
        className={`${styles.render} ${lang_active && styles.show} flex-col align-center items-center  shadow absolute z-50 md:left-[20vw] left-[10vw] top-[90px] w-[180px] h-auto bg-white inline-flex p-2 -m-2 text-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900`}
      >
        <div
          className={`cursor-pointer flex w-full h-[50%] justify-evenly items-center ${props.lang === 0 && "bg-zinc-50"}`}
          onClick={() => {
            props.setLang(0);
            localStorage.setItem("sunsouls-lang", 0);
          }}
        >
          <HE style={{ transform: "scale(0.7)" }} />
          <p>{props.lang === 1 ? "Hebrew" : "עברית"}</p>
        </div>
        <div
          className={`cursor-pointer flex w-full h-[50%] justify-evenly items-center ${props.lang === 1 && "bg-zinc-50"}`}
          onClick={() => {
            props.setLang(1);
            localStorage.setItem("sunsouls-lang", 1);
          }}
        >
          <US style={{ transform: "scale(0.7)" }} />
          <p>{props.lang === 1 ? "English" : "אנגלית"}</p>
        </div>
      </div>
      <div
        className={`absolute overflow-hidden top-[80px] right-0 z-[50] ${styles["render"]} ${styles.container} ${card_active && styles.show}`}
      >
        <div className="inset-x-0 top-0 right-0">
          <div className="flex justify-end px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="w-full max-w-sm overflow-hidden bg-white shadow-md rounded-b-md">
              <div className="px-4 py-6 sm:p-6">
                <div className="flow-root">
                  <ul className="-my-5 divide-y divide-gray-200">
                    {cart.map((item, index) => (
                      <li
                        className="flex py-5 curs cursor-pointer"
                        onClick={(e) => {
                          // console.log(e.target.nodeName);
                          if (
                            e.target.nodeName !== "path" &&
                            e.target.nodeName !== "svg"
                          ) {
                            window.location.href = "/product?id=" + item.id;
                          }
                        }}
                      >
                        <div className="flex-shrink-0">
                          <img
                            className="object-cover w-16 h-16 rounded-lg"
                            src={`data:image/${item.img.type};base64, ${item.img.data}`}
                            alt=""
                          />
                        </div>

                        <div className="flex items-stretch justify-between flex-1 ml-5 space-x-5">
                          <div className="flex flex-col justify-between flex-1">
                            <p className="text-sm font-bold text-gray-900">
                              {item.name[props.lang === 1 ? "EN" : "HE"]}
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              {props.lang === 1 ? "Qty" : "כמות"}: {item.qty}
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              {props.lang === 1 && "Size: "}{" "}
                              {item.size === 0
                                ? "S"
                                : item.size === 1
                                  ? "M"
                                  : item.size === 2
                                    ? "L"
                                    : "XL"}
                              {props.lang === 0 && " :מידה"}
                            </p>
                          </div>

                          <div className="flex flex-col items-end justify-between">
                            <button
                              type="button"
                              onClick={async (e) => {
                                e.preventDefault();
                                if (
                                  localStorage.getItem("sunsouls-logged") ==
                                  true
                                ) {
                                  try {
                                    const response = await fetch(
                                      "https://sunsouls.deviceid.io/cart?id=" +
                                        item.id,
                                      +"&size=" + item.size,
                                      {
                                        method: "DELETE",
                                        headers: {
                                          "Content-Type": "text/plain",
                                          Authorization:
                                            "Bearer " +
                                            localStorage.getItem(
                                              "sunsouls-token",
                                            ),
                                        },
                                      },
                                    );
                                    if (response.status === 200) {
                                      const items = JSON.parse(
                                        localStorage.getItem("sunsouls-cart"),
                                      );
                                      if (
                                        items === null ||
                                        items === undefined ||
                                        !Array.isArray(items)
                                      )
                                        return;
                                      if (items.length === 1) {
                                        setCart([]);
                                        localStorage.setItem(
                                          "sunsouls-cart",
                                          JSON.stringify([]),
                                        );
                                      } else {
                                        var index = 0;
                                        for (const itm of items) {
                                          if (
                                            itm.id === item.id &&
                                            itm.size === item.size
                                          ) {
                                            items.slice(index, 1);
                                            break;
                                          }
                                          index++;
                                        }
                                        setCart(items);
                                        localStorage.setItem(
                                          "sunsouls-cart",
                                          JSON.stringify(items),
                                        );
                                      }
                                    } else {
                                      parseCart(index);
                                    }
                                  } catch (e) {
                                    parseCart(index);
                                  }
                                } else {
                                  parseCart(index);
                                }
                              }}
                              className="inline-flex p-2 -m-2 text-gray-400 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                            >
                              <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>

                            <p className="flex-shrink-0 w-20 text-sm font-bold text-right text-gray-900">
                              {item.price / (props.lang === 0 ? 1 : 3.5) +
                                currencyByLang.get(
                                  props.lang === 1 ? "EN" : "HE",
                                )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <hr className="mt-5 border-gray-200" />

                <div className="flex items-center justify-between mt-5">
                  {props.lang === 0 && (
                    <p className="text-sm font-bold text-gray-900">
                      {cart.reduce((acc, cur) => acc + cur.price * cur.qty, 0) +
                        "₪"}
                    </p>
                  )}
                  <p className="text-sm font-medium text-gray-900">
                    {props.lang === 1 ? "Total" : "סכום כולל"}
                  </p>
                  {props.lang === 1 && (
                    <p className="text-sm font-bold text-gray-900">
                      {cart.reduce((acc, cur) => acc + cur.price * cur.qty, 0) /
                        3.5 +
                        "$"}
                    </p>
                  )}
                </div>

                <div className="mt-5 text-center">
                  <button
                    type="button"
                    onClick={() => {
                      window.location.href = "/checkout";
                    }}
                    className="inline-flex items-center justify-center w-full px-6 py-4 text-sm font-bold text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    {props.lang === 1 ? "Checkout" : "תשלום"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className="relative pb-[280px]"
        onClick={(e) =>
          (e.target.className == "relative" ||
            e.target.className ==
              "flex justify-end px-4 mx-auto max-w-7xl sm:px-6 lg:px-8") &&
          resetMenus()
        }
      >
        <div
          className={`bg-white border-b border-gray-200 ${profile === 1 && "mt-[30px]"}`}
        >
          <div className="flex justify-between items-center h-16 lg:h-[72px]">
            <div className="flex-1 flex justify-center items-center">
              <div className="flex cursor-pointer items-center justify-center gap-1 sm:gap-5 w-[115px]">
                <Menu
                  onClick={() => {
                    set_card_active(false);
                    set_lang_active(false);
                    set_profile_active(false);
                    set_menu_active(true);
                  }}
                />
                <Globe
                  onClick={() => {
                    set_card_active(false);
                    set_menu_active(false);
                    set_profile_active(false);
                    set_lang_active(!lang_active);
                  }}
                />
              </div>
            </div>
            <div className="flex-3 items-center flex-shrink-0">
              <a href="#" title="" className="inline-flex">
                <span className="sr-only"> Sunsouls logo </span>
                <img
                  className="w-[109px] h-[60px] scale-90 sm:scale-100"
                  onClick={() =>
                    window.location.pathname !== "/"
                      ? (window.location.href = "/")
                      : console.log()
                  }
                  src={Logo}
                  alt=""
                />
              </a>
            </div>
            <div className="flex-[1] flex items-center justify-center gap-8 sm:gap-8">
              <User
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (localStorage.getItem("sunsouls-logged") != 1) {
                    window.location.href = "/login";
                  } else {
                    set_lang_active(false);
                    set_menu_active(false);
                    set_card_active(false);
                    set_profile_active(!profile_active);
                  }
                }}
              />

              <Bag
                style={{ cursor: "pointer" }}
                onClick={() => {
                  set_lang_active(false);
                  set_menu_active(false);
                  set_profile_active(false);
                  set_card_active(!card_active);
                }}
              />
            </div>
          </div>
        </div>
      </header>
    </OutsideAlerter>
  );
};

export default Navbar;
