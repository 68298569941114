import React from "react";

const Contact = (props) => {
  return (
    <section className="absolute top-[65px] left-0 w-full h-full py-10 bg-gray-100 sm:py-16 lg:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">
            {props.lang === 0 ? "יצירת קשר" : "Contact us"}
          </h2>
          <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-500">
            {props.lang === 0
              ? "צרו איתנו קשר בנוגע לכל בעיה וקבלו מענה מיידי מהצוות שלנו"
              : "Get in touch with us regarding any matter and recive instant help from our team"}
          </p>
        </div>

        <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
          <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-2">
            <div className="overflow-hidden bg-white rounded-xl">
              <div className="p-6">
                <svg
                  className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <p className="mt-6 text-lg font-medium text-gray-900">
                  contact@sunsouls.store
                </p>
              </div>
            </div>

            <div className="overflow-hidden bg-white rounded-xl">
              <div className="p-6">
                <svg
                  className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <p className="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                  {props.lang === 0
                    ? "בציר 4, מעלות תרשיחא"
                    : "Habatsir 4, Ma'alot-Tarshiha, Israel"}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6 overflow-hidden bg-white rounded-xl">
            <div className="px-6 py-12 sm:p-12">
              <h3 className="text-3xl font-semibold text-center text-gray-900">
                {props.lang === 0 ? "שלחו לנו הודעה" : "Send us a message"}
              </h3>

              <form className="mt-14">
                <div
                  className={`grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4 ${props.lang === 0 && "text-right"}`}
                >
                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "שם מלא" : "Your name"}{" "}
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder={
                          props.lang === 0
                            ? "הכניסו את שמכם המלא"
                            : "Enter your full name"
                        }
                        className={`${props.lang === 0 && "text-right"} block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600`}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "כתובת איימיל" : "Email address"}{" "}
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="email"
                        name=""
                        id=""
                        placeholder={
                          props.lang === 0
                            ? "הכניסו את כתובת האיימיל שלכם"
                            : "Enter your email address"
                        }
                        className={`block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600 ${props.lang === 0 && "text-right"}`}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "מספר טלפון" : "Phone number"}{" "}
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="tel"
                        name=""
                        id=""
                        placeholder={
                          props.lang === 0
                            ? "הכניסו מספר טלפון כולל קידומת"
                            : "Enter your phone number with country code"
                        }
                        className={` ${props.lang === 0 && "text-right"} block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600`}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "נושא הפנייה" : "Topic"}{" "}
                    </label>
                    <div className="mt-2.5 relative">
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder={
                          props.lang === 0
                            ? "פרטו לגבי נושא ההודעה שלכם"
                            : "Describe the topic of your message"
                        }
                        className={`block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600 ${props.lang === 0 && "text-right"}`}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      for=""
                      className="text-base font-medium text-gray-900"
                    >
                      {" "}
                      {props.lang === 0 ? "הודעה" : "Message"}{" "}
                    </label>
                    <div className="mt-2.5 relative">
                      <textarea
                        name=""
                        id=""
                        placeholder=""
                        className={` ${props.lang === 0 && "text-right"} block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600`}
                        rows="4"
                      ></textarea>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-[#d6b47c] border border-transparent rounded-md focus:outline-none hover:bg-blue-700"
                    >
                      {props.lang === 0 ? "שלח" : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
